.badge { 
  box-sizing:border-box;
  font-weight:600;
  display:inline-flex;
  align-items:center;
  vertical-align:middle;
}
.badge-light-gray {
  color: rgba(#66768A, .4);
}

.badge-notifications {
  font-size: .75rem;
  padding: .25rem .4rem;
}

@each $color, $value in $theme-colors {
  .badge-soft-#{$color} {
    color: darken(theme-color($color), 5%);
    background-color: rgba(theme-color($color), .1);
  }
}

.badge-lg {
  font-size: 1.8rem;
  padding: .5rem 1rem;
}