.table thead th {
  font-size: .825rem;
  color: rgba(#374D67, .54);
  padding: .35rem 1rem;
}
.table tbody td {
  vertical-align: middle;
}
.thead-border-top-0 thead th {
  border-top: 0;
}
.table tbody .selected > td {
  background-color: rgba(#5897E0, .05);
}

.table .sort {
  color: inherit;
  display: flex-inline;
  align-items: center;
  text-decoration: none;
  
  &::after {
    margin-left: .5rem;
    content: str-replace(url("data:image/svg+xml;utf8,<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M3 0L6 4H0L3 0ZM3 10L0 6H6L3 10Z' fill='#{rgba(#374D67, .54)}'/></svg>"), "#", "%23");
  }

  &.asc,
  &.desc {
    color: $body-color;
    text-decoration: underline;
  }

  & + .sort {
    margin-left: 1rem;
  }
}


a.rating-link {
 color: theme-color("light-gray");
 &:hover, &.active {
   color: theme-color("warning");
 }
}