.app-chat {
  overflow: hidden;
  .mdk-drawer-layout,
  .mdk-header-layout,
  .mdk-header-layout__content {
    height: 100%;
  }
  .page {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 0;
    margin-top: 0;
  }
  &-container {
    flex: 1 1 0%;
    position: relative;
    height: 100%;
    overflow: hidden;
    z-index: 0;
  }
}