@charset "UTF-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Table of contents:

1. CORE (Bootstrap + MDK Layout)
2. Theme
  2.1 flexbox
  2.2 icons
  2.3 type
  2.4 layout
  2.5 dropdown
  2.6 header
  2.7 search-form
  2.8 navbar
  2.9 navbar-dark
  2.10 navbar-light
  2.11 drawer
  2.12 sidebar
  2.13 card
  2.14 table
  2.15 button
  2.16 breadcrumb
  2.17 form
  2.18 flatpickr
  2.19 input-group
  2.20 progress
  2.21 tabs
  2.22 list-group
  2.23 borders
  2.24 custom-checkbox-toggle
  2.25 alert
  2.26 loader
  2.27 helpers
  2.28 badge
  2.29 avatar
  2.30 chart
  2.31 pagination
  2.32 page-separator
3. Apps
  3.1 apps/trello
  3.2 apps/chat
  3.3 apps/email
  3.4 apps/properties
  3.5 apps/tickets
4. Plugin Overrides
  4.1 daterangepicker
  4.2 toastr
  4.3 fullcalendar
  4.4 preloader
5. Pages
-------------------------------------------------------------------*/
/*------------------------------------------------------------------
[1. CORE]
-------------------------------------------------------------------*/
/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700");
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #1377c9;
  --secondary: #262f36;
  --success: #17b15c;
  --info: #13C0C9;
  --warning: #f0ca4d;
  --danger: #bb3434;
  --light: #f8f9fa;
  --dark: #343a40;
  --blue: #3687e4;
  --teal: #1ea190;
  --purple: #5163C5;
  --dark-gray: #2A2A2A;
  --light-gray: rgba(0, 0, 0, 0.24);
  --muted-light: #DBDFE4;
  --facebook: #3b5998;
  --twitter: #00aced;
  --vuejs: #42b883;
  --angular: #D32F2F;
  --gulp: #cf4647;
  --node: #699f63;
  --gitlab: #e65328;
  --instagram: #3f729b;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #353535;
  text-align: left;
  background-color: #F9F9F9;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #3687e4;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #1861b5;
  text-decoration: underline;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: rgba(0, 0, 0, 0.54);
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #F9F9F9;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid, .container-lg, .container-md, .container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #353535;
}
.table th,
.table td {
  padding: 1rem;
  vertical-align: top;
  border-top: 1px solid #efefef;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #efefef;
}
.table tbody + tbody {
  border-top: 2px solid #efefef;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #efefef;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #efefef;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #353535;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #bdd9f0;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #84b8e3;
}

.table-hover .table-primary:hover {
  background-color: #a8cdeb;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #a8cdeb;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #c2c5c7;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #8e9396;
}

.table-hover .table-secondary:hover {
  background-color: #b5b8bb;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #b5b8bb;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #bee9d1;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #86d6aa;
}

.table-hover .table-success:hover {
  background-color: #abe3c4;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #abe3c4;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bdedf0;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #84dee3;
}

.table-hover .table-info:hover {
  background-color: #a8e7eb;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #a8e7eb;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fbf0cd;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f7e3a2;
}

.table-hover .table-warning:hover {
  background-color: #f9e9b5;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #f9e9b5;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #ecc6c6;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #dc9595;
}

.table-hover .table-danger:hover {
  background-color: #e6b3b3;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #e6b3b3;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-blue,
.table-blue > th,
.table-blue > td {
  background-color: #c7ddf7;
}
.table-blue th,
.table-blue td,
.table-blue thead th,
.table-blue tbody + tbody {
  border-color: #96c1f1;
}

.table-hover .table-blue:hover {
  background-color: #b1cff4;
}
.table-hover .table-blue:hover > td,
.table-hover .table-blue:hover > th {
  background-color: #b1cff4;
}

.table-teal,
.table-teal > th,
.table-teal > td {
  background-color: #c0e5e0;
}
.table-teal th,
.table-teal td,
.table-teal thead th,
.table-teal tbody + tbody {
  border-color: #8acec5;
}

.table-hover .table-teal:hover {
  background-color: #aeded7;
}
.table-hover .table-teal:hover > td,
.table-hover .table-teal:hover > th {
  background-color: #aeded7;
}

.table-purple,
.table-purple > th,
.table-purple > td {
  background-color: #ced3ef;
}
.table-purple th,
.table-purple td,
.table-purple thead th,
.table-purple tbody + tbody {
  border-color: #a5aee1;
}

.table-hover .table-purple:hover {
  background-color: #bbc2e9;
}
.table-hover .table-purple:hover > td,
.table-hover .table-purple:hover > th {
  background-color: #bbc2e9;
}

.table-dark-gray,
.table-dark-gray > th,
.table-dark-gray > td {
  background-color: #c3c3c3;
}
.table-dark-gray th,
.table-dark-gray td,
.table-dark-gray thead th,
.table-dark-gray tbody + tbody {
  border-color: #909090;
}

.table-hover .table-dark-gray:hover {
  background-color: #b6b6b6;
}
.table-hover .table-dark-gray:hover > td,
.table-hover .table-dark-gray:hover > th {
  background-color: #b6b6b6;
}

.table-light-gray,
.table-light-gray > th,
.table-light-gray > td {
  background-color: rgba(242, 242, 242, 0.7872);
}
.table-light-gray th,
.table-light-gray td,
.table-light-gray thead th,
.table-light-gray tbody + tbody {
  border-color: rgba(222, 222, 222, 0.6048);
}

.table-hover .table-light-gray:hover {
  background-color: rgba(229, 229, 229, 0.7872);
}
.table-hover .table-light-gray:hover > td,
.table-hover .table-light-gray:hover > th {
  background-color: rgba(229, 229, 229, 0.7872);
}

.table-muted-light,
.table-muted-light > th,
.table-muted-light > td {
  background-color: #f5f6f7;
}
.table-muted-light th,
.table-muted-light td,
.table-muted-light thead th,
.table-muted-light tbody + tbody {
  border-color: #eceef1;
}

.table-hover .table-muted-light:hover {
  background-color: #e7e9ec;
}
.table-hover .table-muted-light:hover > td,
.table-hover .table-muted-light:hover > th {
  background-color: #e7e9ec;
}

.table-facebook,
.table-facebook > th,
.table-facebook > td {
  background-color: #c8d1e2;
}
.table-facebook th,
.table-facebook td,
.table-facebook thead th,
.table-facebook tbody + tbody {
  border-color: #99a9c9;
}

.table-hover .table-facebook:hover {
  background-color: #b7c3d9;
}
.table-hover .table-facebook:hover > td,
.table-hover .table-facebook:hover > th {
  background-color: #b7c3d9;
}

.table-twitter,
.table-twitter > th,
.table-twitter > td {
  background-color: #b8e8fa;
}
.table-twitter th,
.table-twitter td,
.table-twitter thead th,
.table-twitter tbody + tbody {
  border-color: #7ad4f6;
}

.table-hover .table-twitter:hover {
  background-color: #a0e0f8;
}
.table-hover .table-twitter:hover > td,
.table-hover .table-twitter:hover > th {
  background-color: #a0e0f8;
}

.table-vuejs,
.table-vuejs > th,
.table-vuejs > td {
  background-color: #caebdc;
}
.table-vuejs th,
.table-vuejs td,
.table-vuejs thead th,
.table-vuejs tbody + tbody {
  border-color: #9ddabf;
}

.table-hover .table-vuejs:hover {
  background-color: #b7e4d0;
}
.table-hover .table-vuejs:hover > td,
.table-hover .table-vuejs:hover > th {
  background-color: #b7e4d0;
}

.table-angular,
.table-angular > th,
.table-angular > td {
  background-color: #f3c5c5;
}
.table-angular th,
.table-angular td,
.table-angular thead th,
.table-angular tbody + tbody {
  border-color: #e89393;
}

.table-hover .table-angular:hover {
  background-color: #efb0b0;
}
.table-hover .table-angular:hover > td,
.table-hover .table-angular:hover > th {
  background-color: #efb0b0;
}

.table-gulp,
.table-gulp > th,
.table-gulp > td {
  background-color: #f2cbcb;
}
.table-gulp th,
.table-gulp td,
.table-gulp thead th,
.table-gulp tbody + tbody {
  border-color: #e69f9f;
}

.table-hover .table-gulp:hover {
  background-color: #edb7b7;
}
.table-hover .table-gulp:hover > td,
.table-hover .table-gulp:hover > th {
  background-color: #edb7b7;
}

.table-node,
.table-node > th,
.table-node > td {
  background-color: #d5e4d3;
}
.table-node th,
.table-node td,
.table-node thead th,
.table-node tbody + tbody {
  border-color: #b1cdae;
}

.table-hover .table-node:hover {
  background-color: #c6dac3;
}
.table-hover .table-node:hover > td,
.table-hover .table-node:hover > th {
  background-color: #c6dac3;
}

.table-gitlab,
.table-gitlab > th,
.table-gitlab > td {
  background-color: #f8cfc3;
}
.table-gitlab th,
.table-gitlab td,
.table-gitlab thead th,
.table-gitlab tbody + tbody {
  border-color: #f2a68f;
}

.table-hover .table-gitlab:hover {
  background-color: #f5bdac;
}
.table-hover .table-gitlab:hover > td,
.table-hover .table-gitlab:hover > th {
  background-color: #f5bdac;
}

.table-instagram,
.table-instagram > th,
.table-instagram > td {
  background-color: #c9d8e3;
}
.table-instagram th,
.table-instagram td,
.table-instagram thead th,
.table-instagram tbody + tbody {
  border-color: #9bb6cb;
}

.table-hover .table-instagram:hover {
  background-color: #b8ccda;
}
.table-hover .table-instagram:hover > td,
.table-hover .table-instagram:hover > th {
  background-color: #b8ccda;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #efefef;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.875rem;
  font-size: 0.925rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #efefef;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #1377c9;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(19, 119, 201, 0.25);
}
.form-control::placeholder {
  color: rgba(0, 0, 0, 0.24);
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.35rem + 1px);
  padding-bottom: calc(0.35rem + 1px);
  font-size: 1rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.15rem + 1px);
  padding-bottom: calc(0.15rem + 1px);
  font-size: 0.85rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 0.925rem;
  line-height: 1.5;
  color: #353535;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.3rem + 2px);
  padding: 0.15rem 0.5rem;
  font-size: 0.85rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 0.7rem + 2px);
  padding: 0.35rem 0.85rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1.875rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: rgba(0, 0, 0, 0.54);
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #17b15c;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(23, 177, 92, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #17b15c;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2317b15c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #17b15c;
  box-shadow: 0 0 0 0.2rem rgba(23, 177, 92, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #17b15c;
  padding-right: calc(0.75em + 2.4375rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.875rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2317b15c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.875rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #17b15c;
  box-shadow: 0 0 0 0.2rem rgba(23, 177, 92, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #17b15c;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #17b15c;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #17b15c;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #1dde73;
  background-color: #1dde73;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(23, 177, 92, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #17b15c;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #17b15c;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #17b15c;
  box-shadow: 0 0 0 0.2rem rgba(23, 177, 92, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #bb3434;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(187, 52, 52, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #bb3434;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23bb3434' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23bb3434' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #bb3434;
  box-shadow: 0 0 0 0.2rem rgba(187, 52, 52, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #bb3434;
  padding-right: calc(0.75em + 2.4375rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.875rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23bb3434' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23bb3434' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.875rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #bb3434;
  box-shadow: 0 0 0 0.2rem rgba(187, 52, 52, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #bb3434;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #bb3434;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #bb3434;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #cf5353;
  background-color: #cf5353;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(187, 52, 52, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #bb3434;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #bb3434;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #bb3434;
  box-shadow: 0 0 0 0.2rem rgba(187, 52, 52, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 600;
  color: #353535;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.875rem;
  font-size: 0.925rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #353535;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(19, 119, 201, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #1377c9;
  border-color: #1377c9;
}
.btn-primary:hover {
  color: #fff;
  background-color: #1062a6;
  border-color: #0f5b9a;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #1062a6;
  border-color: #0f5b9a;
  box-shadow: 0 0 0 0.2rem rgba(54, 139, 209, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #1377c9;
  border-color: #1377c9;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0f5b9a;
  border-color: #0d558f;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(54, 139, 209, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #262f36;
  border-color: #262f36;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #161c20;
  border-color: #111518;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #161c20;
  border-color: #111518;
  box-shadow: 0 0 0 0.2rem rgba(71, 78, 84, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #262f36;
  border-color: #262f36;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #111518;
  border-color: #0c0f11;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 78, 84, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #17b15c;
  border-color: #17b15c;
}
.btn-success:hover {
  color: #fff;
  background-color: #138f4a;
  border-color: #118445;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #138f4a;
  border-color: #118445;
  box-shadow: 0 0 0 0.2rem rgba(58, 189, 116, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #17b15c;
  border-color: #17b15c;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #118445;
  border-color: #10793f;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 189, 116, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #13C0C9;
  border-color: #13C0C9;
}
.btn-info:hover {
  color: #fff;
  background-color: #109fa6;
  border-color: #0f939a;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #109fa6;
  border-color: #0f939a;
  box-shadow: 0 0 0 0.2rem rgba(54, 201, 209, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #13C0C9;
  border-color: #13C0C9;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #0f939a;
  border-color: #0d888f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(54, 201, 209, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #f0ca4d;
  border-color: #f0ca4d;
}
.btn-warning:hover {
  color: #212529;
  background-color: #edbf2a;
  border-color: #ecbc1e;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #edbf2a;
  border-color: #ecbc1e;
  box-shadow: 0 0 0 0.2rem rgba(209, 177, 72, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #f0ca4d;
  border-color: #f0ca4d;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #ecbc1e;
  border-color: #eab814;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(209, 177, 72, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #bb3434;
  border-color: #bb3434;
}
.btn-danger:hover {
  color: #fff;
  background-color: #9d2c2c;
  border-color: #932929;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #9d2c2c;
  border-color: #932929;
  box-shadow: 0 0 0 0.2rem rgba(197, 82, 82, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #bb3434;
  border-color: #bb3434;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #932929;
  border-color: #892626;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(197, 82, 82, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-blue {
  color: #fff;
  background-color: #3687e4;
  border-color: #3687e4;
}
.btn-blue:hover {
  color: #fff;
  background-color: #1d73d7;
  border-color: #1b6dcc;
}
.btn-blue:focus, .btn-blue.focus {
  color: #fff;
  background-color: #1d73d7;
  border-color: #1b6dcc;
  box-shadow: 0 0 0 0.2rem rgba(84, 153, 232, 0.5);
}
.btn-blue.disabled, .btn-blue:disabled {
  color: #fff;
  background-color: #3687e4;
  border-color: #3687e4;
}
.btn-blue:not(:disabled):not(.disabled):active, .btn-blue:not(:disabled):not(.disabled).active, .show > .btn-blue.dropdown-toggle {
  color: #fff;
  background-color: #1b6dcc;
  border-color: #1a67c0;
}
.btn-blue:not(:disabled):not(.disabled):active:focus, .btn-blue:not(:disabled):not(.disabled).active:focus, .show > .btn-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(84, 153, 232, 0.5);
}

.btn-teal {
  color: #fff;
  background-color: #1ea190;
  border-color: #1ea190;
}
.btn-teal:hover {
  color: #fff;
  background-color: #188173;
  border-color: #16766a;
}
.btn-teal:focus, .btn-teal.focus {
  color: #fff;
  background-color: #188173;
  border-color: #16766a;
  box-shadow: 0 0 0 0.2rem rgba(64, 175, 161, 0.5);
}
.btn-teal.disabled, .btn-teal:disabled {
  color: #fff;
  background-color: #1ea190;
  border-color: #1ea190;
}
.btn-teal:not(:disabled):not(.disabled):active, .btn-teal:not(:disabled):not(.disabled).active, .show > .btn-teal.dropdown-toggle {
  color: #fff;
  background-color: #16766a;
  border-color: #146b60;
}
.btn-teal:not(:disabled):not(.disabled):active:focus, .btn-teal:not(:disabled):not(.disabled).active:focus, .show > .btn-teal.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(64, 175, 161, 0.5);
}

.btn-purple {
  color: #fff;
  background-color: #5163C5;
  border-color: #5163C5;
}
.btn-purple:hover {
  color: #fff;
  background-color: #3c4fb4;
  border-color: #394aaa;
}
.btn-purple:focus, .btn-purple.focus {
  color: #fff;
  background-color: #3c4fb4;
  border-color: #394aaa;
  box-shadow: 0 0 0 0.2rem rgba(107, 122, 206, 0.5);
}
.btn-purple.disabled, .btn-purple:disabled {
  color: #fff;
  background-color: #5163C5;
  border-color: #5163C5;
}
.btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active, .show > .btn-purple.dropdown-toggle {
  color: #fff;
  background-color: #394aaa;
  border-color: #3646a1;
}
.btn-purple:not(:disabled):not(.disabled):active:focus, .btn-purple:not(:disabled):not(.disabled).active:focus, .show > .btn-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(107, 122, 206, 0.5);
}

.btn-dark-gray {
  color: #fff;
  background-color: #2A2A2A;
  border-color: #2A2A2A;
}
.btn-dark-gray:hover {
  color: #fff;
  background-color: #171717;
  border-color: #111111;
}
.btn-dark-gray:focus, .btn-dark-gray.focus {
  color: #fff;
  background-color: #171717;
  border-color: #111111;
  box-shadow: 0 0 0 0.2rem rgba(74, 74, 74, 0.5);
}
.btn-dark-gray.disabled, .btn-dark-gray:disabled {
  color: #fff;
  background-color: #2A2A2A;
  border-color: #2A2A2A;
}
.btn-dark-gray:not(:disabled):not(.disabled):active, .btn-dark-gray:not(:disabled):not(.disabled).active, .show > .btn-dark-gray.dropdown-toggle {
  color: #fff;
  background-color: #111111;
  border-color: #0a0a0a;
}
.btn-dark-gray:not(:disabled):not(.disabled):active:focus, .btn-dark-gray:not(:disabled):not(.disabled).active:focus, .show > .btn-dark-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(74, 74, 74, 0.5);
}

.btn-light-gray {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.24);
  border-color: rgba(0, 0, 0, 0.24);
}
.btn-light-gray:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.24);
  border-color: rgba(0, 0, 0, 0.24);
}
.btn-light-gray:focus, .btn-light-gray.focus {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.24);
  border-color: rgba(0, 0, 0, 0.24);
  box-shadow: 0 0 0 0.2rem rgba(144, 144, 144, 0.5);
}
.btn-light-gray.disabled, .btn-light-gray:disabled {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.24);
  border-color: rgba(0, 0, 0, 0.24);
}
.btn-light-gray:not(:disabled):not(.disabled):active, .btn-light-gray:not(:disabled):not(.disabled).active, .show > .btn-light-gray.dropdown-toggle {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.24);
  border-color: rgba(0, 0, 0, 0.24);
}
.btn-light-gray:not(:disabled):not(.disabled):active:focus, .btn-light-gray:not(:disabled):not(.disabled).active:focus, .show > .btn-light-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(144, 144, 144, 0.5);
}

.btn-muted-light {
  color: #212529;
  background-color: #DBDFE4;
  border-color: #DBDFE4;
}
.btn-muted-light:hover {
  color: #212529;
  background-color: #c5ccd4;
  border-color: #bec5ce;
}
.btn-muted-light:focus, .btn-muted-light.focus {
  color: #212529;
  background-color: #c5ccd4;
  border-color: #bec5ce;
  box-shadow: 0 0 0 0.2rem rgba(191, 195, 200, 0.5);
}
.btn-muted-light.disabled, .btn-muted-light:disabled {
  color: #212529;
  background-color: #DBDFE4;
  border-color: #DBDFE4;
}
.btn-muted-light:not(:disabled):not(.disabled):active, .btn-muted-light:not(:disabled):not(.disabled).active, .show > .btn-muted-light.dropdown-toggle {
  color: #212529;
  background-color: #bec5ce;
  border-color: #b7bfc9;
}
.btn-muted-light:not(:disabled):not(.disabled):active:focus, .btn-muted-light:not(:disabled):not(.disabled).active:focus, .show > .btn-muted-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(191, 195, 200, 0.5);
}

.btn-facebook {
  color: #fff;
  background-color: #3b5998;
  border-color: #3b5998;
}
.btn-facebook:hover {
  color: #fff;
  background-color: #30497c;
  border-color: #2d4373;
}
.btn-facebook:focus, .btn-facebook.focus {
  color: #fff;
  background-color: #30497c;
  border-color: #2d4373;
  box-shadow: 0 0 0 0.2rem rgba(88, 114, 167, 0.5);
}
.btn-facebook.disabled, .btn-facebook:disabled {
  color: #fff;
  background-color: #3b5998;
  border-color: #3b5998;
}
.btn-facebook:not(:disabled):not(.disabled):active, .btn-facebook:not(:disabled):not(.disabled).active, .show > .btn-facebook.dropdown-toggle {
  color: #fff;
  background-color: #2d4373;
  border-color: #293e6a;
}
.btn-facebook:not(:disabled):not(.disabled):active:focus, .btn-facebook:not(:disabled):not(.disabled).active:focus, .show > .btn-facebook.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 114, 167, 0.5);
}

.btn-twitter {
  color: #fff;
  background-color: #00aced;
  border-color: #00aced;
}
.btn-twitter:hover {
  color: #fff;
  background-color: #0090c7;
  border-color: #0087ba;
}
.btn-twitter:focus, .btn-twitter.focus {
  color: #fff;
  background-color: #0090c7;
  border-color: #0087ba;
  box-shadow: 0 0 0 0.2rem rgba(38, 184, 240, 0.5);
}
.btn-twitter.disabled, .btn-twitter:disabled {
  color: #fff;
  background-color: #00aced;
  border-color: #00aced;
}
.btn-twitter:not(:disabled):not(.disabled):active, .btn-twitter:not(:disabled):not(.disabled).active, .show > .btn-twitter.dropdown-toggle {
  color: #fff;
  background-color: #0087ba;
  border-color: #007ead;
}
.btn-twitter:not(:disabled):not(.disabled):active:focus, .btn-twitter:not(:disabled):not(.disabled).active:focus, .show > .btn-twitter.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 184, 240, 0.5);
}

.btn-vuejs {
  color: #fff;
  background-color: #42b883;
  border-color: #42b883;
}
.btn-vuejs:hover {
  color: #fff;
  background-color: #389c6f;
  border-color: #359268;
}
.btn-vuejs:focus, .btn-vuejs.focus {
  color: #fff;
  background-color: #389c6f;
  border-color: #359268;
  box-shadow: 0 0 0 0.2rem rgba(94, 195, 150, 0.5);
}
.btn-vuejs.disabled, .btn-vuejs:disabled {
  color: #fff;
  background-color: #42b883;
  border-color: #42b883;
}
.btn-vuejs:not(:disabled):not(.disabled):active, .btn-vuejs:not(:disabled):not(.disabled).active, .show > .btn-vuejs.dropdown-toggle {
  color: #fff;
  background-color: #359268;
  border-color: #318962;
}
.btn-vuejs:not(:disabled):not(.disabled):active:focus, .btn-vuejs:not(:disabled):not(.disabled).active:focus, .show > .btn-vuejs.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(94, 195, 150, 0.5);
}

.btn-angular {
  color: #fff;
  background-color: #D32F2F;
  border-color: #D32F2F;
}
.btn-angular:hover {
  color: #fff;
  background-color: #b52626;
  border-color: #ab2424;
}
.btn-angular:focus, .btn-angular.focus {
  color: #fff;
  background-color: #b52626;
  border-color: #ab2424;
  box-shadow: 0 0 0 0.2rem rgba(218, 78, 78, 0.5);
}
.btn-angular.disabled, .btn-angular:disabled {
  color: #fff;
  background-color: #D32F2F;
  border-color: #D32F2F;
}
.btn-angular:not(:disabled):not(.disabled):active, .btn-angular:not(:disabled):not(.disabled).active, .show > .btn-angular.dropdown-toggle {
  color: #fff;
  background-color: #ab2424;
  border-color: #a02222;
}
.btn-angular:not(:disabled):not(.disabled):active:focus, .btn-angular:not(:disabled):not(.disabled).active:focus, .show > .btn-angular.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(218, 78, 78, 0.5);
}

.btn-gulp {
  color: #fff;
  background-color: #cf4647;
  border-color: #cf4647;
}
.btn-gulp:hover {
  color: #fff;
  background-color: #be3132;
  border-color: #b32f30;
}
.btn-gulp:focus, .btn-gulp.focus {
  color: #fff;
  background-color: #be3132;
  border-color: #b32f30;
  box-shadow: 0 0 0 0.2rem rgba(214, 98, 99, 0.5);
}
.btn-gulp.disabled, .btn-gulp:disabled {
  color: #fff;
  background-color: #cf4647;
  border-color: #cf4647;
}
.btn-gulp:not(:disabled):not(.disabled):active, .btn-gulp:not(:disabled):not(.disabled).active, .show > .btn-gulp.dropdown-toggle {
  color: #fff;
  background-color: #b32f30;
  border-color: #a92c2d;
}
.btn-gulp:not(:disabled):not(.disabled):active:focus, .btn-gulp:not(:disabled):not(.disabled).active:focus, .show > .btn-gulp.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(214, 98, 99, 0.5);
}

.btn-node {
  color: #fff;
  background-color: #699f63;
  border-color: #699f63;
}
.btn-node:hover {
  color: #fff;
  background-color: #598854;
  border-color: #54804f;
}
.btn-node:focus, .btn-node.focus {
  color: #fff;
  background-color: #598854;
  border-color: #54804f;
  box-shadow: 0 0 0 0.2rem rgba(128, 173, 122, 0.5);
}
.btn-node.disabled, .btn-node:disabled {
  color: #fff;
  background-color: #699f63;
  border-color: #699f63;
}
.btn-node:not(:disabled):not(.disabled):active, .btn-node:not(:disabled):not(.disabled).active, .show > .btn-node.dropdown-toggle {
  color: #fff;
  background-color: #54804f;
  border-color: #4f784a;
}
.btn-node:not(:disabled):not(.disabled):active:focus, .btn-node:not(:disabled):not(.disabled).active:focus, .show > .btn-node.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(128, 173, 122, 0.5);
}

.btn-gitlab {
  color: #fff;
  background-color: #e65328;
  border-color: #e65328;
}
.btn-gitlab:hover {
  color: #fff;
  background-color: #d04218;
  border-color: #c43e17;
}
.btn-gitlab:focus, .btn-gitlab.focus {
  color: #fff;
  background-color: #d04218;
  border-color: #c43e17;
  box-shadow: 0 0 0 0.2rem rgba(234, 109, 72, 0.5);
}
.btn-gitlab.disabled, .btn-gitlab:disabled {
  color: #fff;
  background-color: #e65328;
  border-color: #e65328;
}
.btn-gitlab:not(:disabled):not(.disabled):active, .btn-gitlab:not(:disabled):not(.disabled).active, .show > .btn-gitlab.dropdown-toggle {
  color: #fff;
  background-color: #c43e17;
  border-color: #b93a15;
}
.btn-gitlab:not(:disabled):not(.disabled):active:focus, .btn-gitlab:not(:disabled):not(.disabled).active:focus, .show > .btn-gitlab.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(234, 109, 72, 0.5);
}

.btn-instagram {
  color: #fff;
  background-color: #3f729b;
  border-color: #3f729b;
}
.btn-instagram:hover {
  color: #fff;
  background-color: #345e80;
  border-color: #305777;
}
.btn-instagram:focus, .btn-instagram.focus {
  color: #fff;
  background-color: #345e80;
  border-color: #305777;
  box-shadow: 0 0 0 0.2rem rgba(92, 135, 170, 0.5);
}
.btn-instagram.disabled, .btn-instagram:disabled {
  color: #fff;
  background-color: #3f729b;
  border-color: #3f729b;
}
.btn-instagram:not(:disabled):not(.disabled):active, .btn-instagram:not(:disabled):not(.disabled).active, .show > .btn-instagram.dropdown-toggle {
  color: #fff;
  background-color: #305777;
  border-color: #2d516e;
}
.btn-instagram:not(:disabled):not(.disabled):active:focus, .btn-instagram:not(:disabled):not(.disabled).active:focus, .show > .btn-instagram.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 135, 170, 0.5);
}

.btn-outline-primary {
  color: #1377c9;
  border-color: #1377c9;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #1377c9;
  border-color: #1377c9;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(19, 119, 201, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #1377c9;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #1377c9;
  border-color: #1377c9;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(19, 119, 201, 0.5);
}

.btn-outline-secondary {
  color: #262f36;
  border-color: #262f36;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #262f36;
  border-color: #262f36;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 47, 54, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #262f36;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #262f36;
  border-color: #262f36;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 47, 54, 0.5);
}

.btn-outline-success {
  color: #17b15c;
  border-color: #17b15c;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #17b15c;
  border-color: #17b15c;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 177, 92, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #17b15c;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #17b15c;
  border-color: #17b15c;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 177, 92, 0.5);
}

.btn-outline-info {
  color: #13C0C9;
  border-color: #13C0C9;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #13C0C9;
  border-color: #13C0C9;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(19, 192, 201, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #13C0C9;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #13C0C9;
  border-color: #13C0C9;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(19, 192, 201, 0.5);
}

.btn-outline-warning {
  color: #f0ca4d;
  border-color: #f0ca4d;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #f0ca4d;
  border-color: #f0ca4d;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 202, 77, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #f0ca4d;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #f0ca4d;
  border-color: #f0ca4d;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 202, 77, 0.5);
}

.btn-outline-danger {
  color: #bb3434;
  border-color: #bb3434;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #bb3434;
  border-color: #bb3434;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(187, 52, 52, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #bb3434;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #bb3434;
  border-color: #bb3434;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(187, 52, 52, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-blue {
  color: #3687e4;
  border-color: #3687e4;
}
.btn-outline-blue:hover {
  color: #fff;
  background-color: #3687e4;
  border-color: #3687e4;
}
.btn-outline-blue:focus, .btn-outline-blue.focus {
  box-shadow: 0 0 0 0.2rem rgba(54, 135, 228, 0.5);
}
.btn-outline-blue.disabled, .btn-outline-blue:disabled {
  color: #3687e4;
  background-color: transparent;
}
.btn-outline-blue:not(:disabled):not(.disabled):active, .btn-outline-blue:not(:disabled):not(.disabled).active, .show > .btn-outline-blue.dropdown-toggle {
  color: #fff;
  background-color: #3687e4;
  border-color: #3687e4;
}
.btn-outline-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-blue:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(54, 135, 228, 0.5);
}

.btn-outline-teal {
  color: #1ea190;
  border-color: #1ea190;
}
.btn-outline-teal:hover {
  color: #fff;
  background-color: #1ea190;
  border-color: #1ea190;
}
.btn-outline-teal:focus, .btn-outline-teal.focus {
  box-shadow: 0 0 0 0.2rem rgba(30, 161, 144, 0.5);
}
.btn-outline-teal.disabled, .btn-outline-teal:disabled {
  color: #1ea190;
  background-color: transparent;
}
.btn-outline-teal:not(:disabled):not(.disabled):active, .btn-outline-teal:not(:disabled):not(.disabled).active, .show > .btn-outline-teal.dropdown-toggle {
  color: #fff;
  background-color: #1ea190;
  border-color: #1ea190;
}
.btn-outline-teal:not(:disabled):not(.disabled):active:focus, .btn-outline-teal:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-teal.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(30, 161, 144, 0.5);
}

.btn-outline-purple {
  color: #5163C5;
  border-color: #5163C5;
}
.btn-outline-purple:hover {
  color: #fff;
  background-color: #5163C5;
  border-color: #5163C5;
}
.btn-outline-purple:focus, .btn-outline-purple.focus {
  box-shadow: 0 0 0 0.2rem rgba(81, 99, 197, 0.5);
}
.btn-outline-purple.disabled, .btn-outline-purple:disabled {
  color: #5163C5;
  background-color: transparent;
}
.btn-outline-purple:not(:disabled):not(.disabled):active, .btn-outline-purple:not(:disabled):not(.disabled).active, .show > .btn-outline-purple.dropdown-toggle {
  color: #fff;
  background-color: #5163C5;
  border-color: #5163C5;
}
.btn-outline-purple:not(:disabled):not(.disabled):active:focus, .btn-outline-purple:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(81, 99, 197, 0.5);
}

.btn-outline-dark-gray {
  color: #2A2A2A;
  border-color: #2A2A2A;
}
.btn-outline-dark-gray:hover {
  color: #fff;
  background-color: #2A2A2A;
  border-color: #2A2A2A;
}
.btn-outline-dark-gray:focus, .btn-outline-dark-gray.focus {
  box-shadow: 0 0 0 0.2rem rgba(42, 42, 42, 0.5);
}
.btn-outline-dark-gray.disabled, .btn-outline-dark-gray:disabled {
  color: #2A2A2A;
  background-color: transparent;
}
.btn-outline-dark-gray:not(:disabled):not(.disabled):active, .btn-outline-dark-gray:not(:disabled):not(.disabled).active, .show > .btn-outline-dark-gray.dropdown-toggle {
  color: #fff;
  background-color: #2A2A2A;
  border-color: #2A2A2A;
}
.btn-outline-dark-gray:not(:disabled):not(.disabled):active:focus, .btn-outline-dark-gray:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(42, 42, 42, 0.5);
}

.btn-outline-light-gray {
  color: rgba(0, 0, 0, 0.24);
  border-color: rgba(0, 0, 0, 0.24);
}
.btn-outline-light-gray:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.24);
  border-color: rgba(0, 0, 0, 0.24);
}
.btn-outline-light-gray:focus, .btn-outline-light-gray.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}
.btn-outline-light-gray.disabled, .btn-outline-light-gray:disabled {
  color: rgba(0, 0, 0, 0.24);
  background-color: transparent;
}
.btn-outline-light-gray:not(:disabled):not(.disabled):active, .btn-outline-light-gray:not(:disabled):not(.disabled).active, .show > .btn-outline-light-gray.dropdown-toggle {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.24);
  border-color: rgba(0, 0, 0, 0.24);
}
.btn-outline-light-gray:not(:disabled):not(.disabled):active:focus, .btn-outline-light-gray:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-muted-light {
  color: #DBDFE4;
  border-color: #DBDFE4;
}
.btn-outline-muted-light:hover {
  color: #212529;
  background-color: #DBDFE4;
  border-color: #DBDFE4;
}
.btn-outline-muted-light:focus, .btn-outline-muted-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(219, 223, 228, 0.5);
}
.btn-outline-muted-light.disabled, .btn-outline-muted-light:disabled {
  color: #DBDFE4;
  background-color: transparent;
}
.btn-outline-muted-light:not(:disabled):not(.disabled):active, .btn-outline-muted-light:not(:disabled):not(.disabled).active, .show > .btn-outline-muted-light.dropdown-toggle {
  color: #212529;
  background-color: #DBDFE4;
  border-color: #DBDFE4;
}
.btn-outline-muted-light:not(:disabled):not(.disabled):active:focus, .btn-outline-muted-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-muted-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(219, 223, 228, 0.5);
}

.btn-outline-facebook {
  color: #3b5998;
  border-color: #3b5998;
}
.btn-outline-facebook:hover {
  color: #fff;
  background-color: #3b5998;
  border-color: #3b5998;
}
.btn-outline-facebook:focus, .btn-outline-facebook.focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 89, 152, 0.5);
}
.btn-outline-facebook.disabled, .btn-outline-facebook:disabled {
  color: #3b5998;
  background-color: transparent;
}
.btn-outline-facebook:not(:disabled):not(.disabled):active, .btn-outline-facebook:not(:disabled):not(.disabled).active, .show > .btn-outline-facebook.dropdown-toggle {
  color: #fff;
  background-color: #3b5998;
  border-color: #3b5998;
}
.btn-outline-facebook:not(:disabled):not(.disabled):active:focus, .btn-outline-facebook:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-facebook.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 89, 152, 0.5);
}

.btn-outline-twitter {
  color: #00aced;
  border-color: #00aced;
}
.btn-outline-twitter:hover {
  color: #fff;
  background-color: #00aced;
  border-color: #00aced;
}
.btn-outline-twitter:focus, .btn-outline-twitter.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 172, 237, 0.5);
}
.btn-outline-twitter.disabled, .btn-outline-twitter:disabled {
  color: #00aced;
  background-color: transparent;
}
.btn-outline-twitter:not(:disabled):not(.disabled):active, .btn-outline-twitter:not(:disabled):not(.disabled).active, .show > .btn-outline-twitter.dropdown-toggle {
  color: #fff;
  background-color: #00aced;
  border-color: #00aced;
}
.btn-outline-twitter:not(:disabled):not(.disabled):active:focus, .btn-outline-twitter:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-twitter.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 172, 237, 0.5);
}

.btn-outline-vuejs {
  color: #42b883;
  border-color: #42b883;
}
.btn-outline-vuejs:hover {
  color: #fff;
  background-color: #42b883;
  border-color: #42b883;
}
.btn-outline-vuejs:focus, .btn-outline-vuejs.focus {
  box-shadow: 0 0 0 0.2rem rgba(66, 184, 131, 0.5);
}
.btn-outline-vuejs.disabled, .btn-outline-vuejs:disabled {
  color: #42b883;
  background-color: transparent;
}
.btn-outline-vuejs:not(:disabled):not(.disabled):active, .btn-outline-vuejs:not(:disabled):not(.disabled).active, .show > .btn-outline-vuejs.dropdown-toggle {
  color: #fff;
  background-color: #42b883;
  border-color: #42b883;
}
.btn-outline-vuejs:not(:disabled):not(.disabled):active:focus, .btn-outline-vuejs:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-vuejs.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(66, 184, 131, 0.5);
}

.btn-outline-angular {
  color: #D32F2F;
  border-color: #D32F2F;
}
.btn-outline-angular:hover {
  color: #fff;
  background-color: #D32F2F;
  border-color: #D32F2F;
}
.btn-outline-angular:focus, .btn-outline-angular.focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 47, 47, 0.5);
}
.btn-outline-angular.disabled, .btn-outline-angular:disabled {
  color: #D32F2F;
  background-color: transparent;
}
.btn-outline-angular:not(:disabled):not(.disabled):active, .btn-outline-angular:not(:disabled):not(.disabled).active, .show > .btn-outline-angular.dropdown-toggle {
  color: #fff;
  background-color: #D32F2F;
  border-color: #D32F2F;
}
.btn-outline-angular:not(:disabled):not(.disabled):active:focus, .btn-outline-angular:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-angular.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 47, 47, 0.5);
}

.btn-outline-gulp {
  color: #cf4647;
  border-color: #cf4647;
}
.btn-outline-gulp:hover {
  color: #fff;
  background-color: #cf4647;
  border-color: #cf4647;
}
.btn-outline-gulp:focus, .btn-outline-gulp.focus {
  box-shadow: 0 0 0 0.2rem rgba(207, 70, 71, 0.5);
}
.btn-outline-gulp.disabled, .btn-outline-gulp:disabled {
  color: #cf4647;
  background-color: transparent;
}
.btn-outline-gulp:not(:disabled):not(.disabled):active, .btn-outline-gulp:not(:disabled):not(.disabled).active, .show > .btn-outline-gulp.dropdown-toggle {
  color: #fff;
  background-color: #cf4647;
  border-color: #cf4647;
}
.btn-outline-gulp:not(:disabled):not(.disabled):active:focus, .btn-outline-gulp:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-gulp.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(207, 70, 71, 0.5);
}

.btn-outline-node {
  color: #699f63;
  border-color: #699f63;
}
.btn-outline-node:hover {
  color: #fff;
  background-color: #699f63;
  border-color: #699f63;
}
.btn-outline-node:focus, .btn-outline-node.focus {
  box-shadow: 0 0 0 0.2rem rgba(105, 159, 99, 0.5);
}
.btn-outline-node.disabled, .btn-outline-node:disabled {
  color: #699f63;
  background-color: transparent;
}
.btn-outline-node:not(:disabled):not(.disabled):active, .btn-outline-node:not(:disabled):not(.disabled).active, .show > .btn-outline-node.dropdown-toggle {
  color: #fff;
  background-color: #699f63;
  border-color: #699f63;
}
.btn-outline-node:not(:disabled):not(.disabled):active:focus, .btn-outline-node:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-node.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(105, 159, 99, 0.5);
}

.btn-outline-gitlab {
  color: #e65328;
  border-color: #e65328;
}
.btn-outline-gitlab:hover {
  color: #fff;
  background-color: #e65328;
  border-color: #e65328;
}
.btn-outline-gitlab:focus, .btn-outline-gitlab.focus {
  box-shadow: 0 0 0 0.2rem rgba(230, 83, 40, 0.5);
}
.btn-outline-gitlab.disabled, .btn-outline-gitlab:disabled {
  color: #e65328;
  background-color: transparent;
}
.btn-outline-gitlab:not(:disabled):not(.disabled):active, .btn-outline-gitlab:not(:disabled):not(.disabled).active, .show > .btn-outline-gitlab.dropdown-toggle {
  color: #fff;
  background-color: #e65328;
  border-color: #e65328;
}
.btn-outline-gitlab:not(:disabled):not(.disabled):active:focus, .btn-outline-gitlab:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-gitlab.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(230, 83, 40, 0.5);
}

.btn-outline-instagram {
  color: #3f729b;
  border-color: #3f729b;
}
.btn-outline-instagram:hover {
  color: #fff;
  background-color: #3f729b;
  border-color: #3f729b;
}
.btn-outline-instagram:focus, .btn-outline-instagram.focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 114, 155, 0.5);
}
.btn-outline-instagram.disabled, .btn-outline-instagram:disabled {
  color: #3f729b;
  background-color: transparent;
}
.btn-outline-instagram:not(:disabled):not(.disabled):active, .btn-outline-instagram:not(:disabled):not(.disabled).active, .show > .btn-outline-instagram.dropdown-toggle {
  color: #fff;
  background-color: #3f729b;
  border-color: #3f729b;
}
.btn-outline-instagram:not(:disabled):not(.disabled):active:focus, .btn-outline-instagram:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-instagram.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 114, 155, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #3687e4;
  text-decoration: none;
}
.btn-link:hover {
  color: #1861b5;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.35rem 0.85rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.15rem 0.5rem;
  font-size: 0.85rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.625rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #353535;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #efefef;
  border-radius: 5px;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.25rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #1377c9;
  text-decoration: none;
  background-color: transparent;
}
.dropdown-item.active, .dropdown-item:active {
  color: #1377c9;
  text-decoration: none;
  background-color: transparent;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.625rem 1.25rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.25rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.65625rem;
  padding-left: 0.65625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.6375rem;
  padding-left: 0.6375rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 0%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.875rem;
  margin-bottom: 0;
  font-size: 0.925rem;
  font-weight: 400;
  line-height: 1.5;
  color: #2A2A2A;
  text-align: center;
  white-space: nowrap;
  background-color: white;
  border: 1px solid #efefef;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 0.7rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.35rem 0.85rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.3rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.15rem 0.5rem;
  font-size: 0.85rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.875rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #1377c9;
  background-color: #1377c9;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(19, 119, 201, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #1377c9;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #99ccf5;
  border-color: #99ccf5;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #1377c9;
  background-color: #1377c9;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(19, 119, 201, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(19, 119, 201, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(19, 119, 201, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(19, 119, 201, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.875rem 0.375rem 0.875rem;
  font-size: 0.925rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.875rem center/8px 10px;
  border: 1px solid #efefef;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #1377c9;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(19, 119, 201, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.875rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.3rem + 2px);
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
  padding-left: 0.5rem;
  font-size: 0.85rem;
}

.custom-select-lg {
  height: calc(1.5em + 0.7rem + 2px);
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
  padding-left: 0.85rem;
  font-size: 1rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #1377c9;
  box-shadow: 0 0 0 0.2rem rgba(19, 119, 201, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #efefef;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.875rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: white;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: none;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #F9F9F9, 0 0 0 0.2rem rgba(19, 119, 201, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #F9F9F9, 0 0 0 0.2rem rgba(19, 119, 201, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #F9F9F9, 0 0 0 0.2rem rgba(19, 119, 201, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #1377c9;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #99ccf5;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #1377c9;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #99ccf5;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #1377c9;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #99ccf5;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 0 solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: 0;
}
.nav-tabs .nav-link {
  border: 0 solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: theme("primary");
  background-color: #fff;
  border-color: #efefef;
}
.nav-tabs .dropdown-menu {
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #1377c9;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1.25rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1.25rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.54);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.54);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.54)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.54);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #efefef;
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid #efefef;
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid #efefef;
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: 0;
  line-height: 30px;
  color: #1377c9;
  background-color: #F9F9F9;
  border: 0 solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #1861b5;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(19, 119, 201, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #1377c9;
  border-color: #1377c9;
}
.page-item.disabled .page-link {
  color: rgba(0, 0, 0, 0.6);
  pointer-events: none;
  cursor: auto;
  background-color: rgba(249, 249, 249, 0.5);
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25rem 0.25rem;
  font-size: 0.675rem;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 2px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #1377c9;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0f5b9a;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(19, 119, 201, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #262f36;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #111518;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 47, 54, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #17b15c;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #118445;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 177, 92, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #13C0C9;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #0f939a;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(19, 192, 201, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #f0ca4d;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #ecbc1e;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(240, 202, 77, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #bb3434;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #932929;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(187, 52, 52, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.badge-blue {
  color: #fff;
  background-color: #3687e4;
}
a.badge-blue:hover, a.badge-blue:focus {
  color: #fff;
  background-color: #1b6dcc;
}
a.badge-blue:focus, a.badge-blue.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(54, 135, 228, 0.5);
}

.badge-teal {
  color: #fff;
  background-color: #1ea190;
}
a.badge-teal:hover, a.badge-teal:focus {
  color: #fff;
  background-color: #16766a;
}
a.badge-teal:focus, a.badge-teal.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(30, 161, 144, 0.5);
}

.badge-purple {
  color: #fff;
  background-color: #5163C5;
}
a.badge-purple:hover, a.badge-purple:focus {
  color: #fff;
  background-color: #394aaa;
}
a.badge-purple:focus, a.badge-purple.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(81, 99, 197, 0.5);
}

.badge-dark-gray {
  color: #fff;
  background-color: #2A2A2A;
}
a.badge-dark-gray:hover, a.badge-dark-gray:focus {
  color: #fff;
  background-color: #111111;
}
a.badge-dark-gray:focus, a.badge-dark-gray.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(42, 42, 42, 0.5);
}

.badge-light-gray {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.24);
}
a.badge-light-gray:hover, a.badge-light-gray:focus {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.24);
}
a.badge-light-gray:focus, a.badge-light-gray.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.badge-muted-light {
  color: #212529;
  background-color: #DBDFE4;
}
a.badge-muted-light:hover, a.badge-muted-light:focus {
  color: #212529;
  background-color: #bec5ce;
}
a.badge-muted-light:focus, a.badge-muted-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(219, 223, 228, 0.5);
}

.badge-facebook {
  color: #fff;
  background-color: #3b5998;
}
a.badge-facebook:hover, a.badge-facebook:focus {
  color: #fff;
  background-color: #2d4373;
}
a.badge-facebook:focus, a.badge-facebook.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(59, 89, 152, 0.5);
}

.badge-twitter {
  color: #fff;
  background-color: #00aced;
}
a.badge-twitter:hover, a.badge-twitter:focus {
  color: #fff;
  background-color: #0087ba;
}
a.badge-twitter:focus, a.badge-twitter.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 172, 237, 0.5);
}

.badge-vuejs {
  color: #fff;
  background-color: #42b883;
}
a.badge-vuejs:hover, a.badge-vuejs:focus {
  color: #fff;
  background-color: #359268;
}
a.badge-vuejs:focus, a.badge-vuejs.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(66, 184, 131, 0.5);
}

.badge-angular {
  color: #fff;
  background-color: #D32F2F;
}
a.badge-angular:hover, a.badge-angular:focus {
  color: #fff;
  background-color: #ab2424;
}
a.badge-angular:focus, a.badge-angular.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(211, 47, 47, 0.5);
}

.badge-gulp {
  color: #fff;
  background-color: #cf4647;
}
a.badge-gulp:hover, a.badge-gulp:focus {
  color: #fff;
  background-color: #b32f30;
}
a.badge-gulp:focus, a.badge-gulp.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(207, 70, 71, 0.5);
}

.badge-node {
  color: #fff;
  background-color: #699f63;
}
a.badge-node:hover, a.badge-node:focus {
  color: #fff;
  background-color: #54804f;
}
a.badge-node:focus, a.badge-node.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(105, 159, 99, 0.5);
}

.badge-gitlab {
  color: #fff;
  background-color: #e65328;
}
a.badge-gitlab:hover, a.badge-gitlab:focus {
  color: #fff;
  background-color: #c43e17;
}
a.badge-gitlab:focus, a.badge-gitlab.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(230, 83, 40, 0.5);
}

.badge-instagram {
  color: #fff;
  background-color: #3f729b;
}
a.badge-instagram:hover, a.badge-instagram:focus {
  color: #fff;
  background-color: #305777;
}
a.badge-instagram:focus, a.badge-instagram.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(63, 114, 155, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #0a3e69;
  background-color: #d0e4f4;
  border-color: #bdd9f0;
}
.alert-primary hr {
  border-top-color: #a8cdeb;
}
.alert-primary .alert-link {
  color: #06233a;
}

.alert-secondary {
  color: #14181c;
  background-color: #d4d5d7;
  border-color: #c2c5c7;
}
.alert-secondary hr {
  border-top-color: #b5b8bb;
}
.alert-secondary .alert-link {
  color: black;
}

.alert-success {
  color: #0c5c30;
  background-color: #d1efde;
  border-color: #bee9d1;
}
.alert-success hr {
  border-top-color: #abe3c4;
}
.alert-success .alert-link {
  color: #062f18;
}

.alert-info {
  color: #0a6469;
  background-color: #d0f2f4;
  border-color: #bdedf0;
}
.alert-info hr {
  border-top-color: #a8e7eb;
}
.alert-info .alert-link {
  color: #06383a;
}

.alert-warning {
  color: #7d6928;
  background-color: #fcf4db;
  border-color: #fbf0cd;
}
.alert-warning hr {
  border-top-color: #f9e9b5;
}
.alert-warning .alert-link {
  color: #56491c;
}

.alert-danger {
  color: #611b1b;
  background-color: #f1d6d6;
  border-color: #ecc6c6;
}
.alert-danger hr {
  border-top-color: #e6b3b3;
}
.alert-danger .alert-link {
  color: #391010;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

.alert-blue {
  color: #1c4677;
  background-color: #d7e7fa;
  border-color: #c7ddf7;
}
.alert-blue hr {
  border-top-color: #b1cff4;
}
.alert-blue .alert-link {
  color: #122e4e;
}

.alert-teal {
  color: #10544b;
  background-color: #d2ece9;
  border-color: #c0e5e0;
}
.alert-teal hr {
  border-top-color: #aeded7;
}
.alert-teal .alert-link {
  color: #082925;
}

.alert-purple {
  color: #2a3366;
  background-color: #dce0f3;
  border-color: #ced3ef;
}
.alert-purple hr {
  border-top-color: #bbc2e9;
}
.alert-purple .alert-link {
  color: #1b2142;
}

.alert-dark-gray {
  color: #161616;
  background-color: #d4d4d4;
  border-color: #c3c3c3;
}
.alert-dark-gray hr {
  border-top-color: #b6b6b6;
}
.alert-dark-gray .alert-link {
  color: black;
}

.alert-light-gray {
  color: rgba(0, 0, 0, 0.6048);
  background-color: rgba(247, 247, 247, 0.848);
  border-color: rgba(242, 242, 242, 0.7872);
}
.alert-light-gray hr {
  border-top-color: rgba(229, 229, 229, 0.7872);
}
.alert-light-gray .alert-link {
  color: rgba(0, 0, 0, 0.6048);
}

.alert-muted-light {
  color: #727477;
  background-color: #f8f9fa;
  border-color: #f5f6f7;
}
.alert-muted-light hr {
  border-top-color: #e7e9ec;
}
.alert-muted-light .alert-link {
  color: #595b5d;
}

.alert-facebook {
  color: #1f2e4f;
  background-color: #d8deea;
  border-color: #c8d1e2;
}
.alert-facebook hr {
  border-top-color: #b7c3d9;
}
.alert-facebook .alert-link {
  color: #11192a;
}

.alert-twitter {
  color: #00597b;
  background-color: #cceefb;
  border-color: #b8e8fa;
}
.alert-twitter hr {
  border-top-color: #a0e0f8;
}
.alert-twitter .alert-link {
  color: #003448;
}

.alert-vuejs {
  color: #226044;
  background-color: #d9f1e6;
  border-color: #caebdc;
}
.alert-vuejs hr {
  border-top-color: #b7e4d0;
}
.alert-vuejs .alert-link {
  color: #153a29;
}

.alert-angular {
  color: #6e1818;
  background-color: #f6d5d5;
  border-color: #f3c5c5;
}
.alert-angular hr {
  border-top-color: #efb0b0;
}
.alert-angular .alert-link {
  color: #440f0f;
}

.alert-gulp {
  color: #6c2425;
  background-color: #f5dada;
  border-color: #f2cbcb;
}
.alert-gulp hr {
  border-top-color: #edb7b7;
}
.alert-gulp .alert-link {
  color: #461718;
}

.alert-node {
  color: #375333;
  background-color: #e1ece0;
  border-color: #d5e4d3;
}
.alert-node hr {
  border-top-color: #c6dac3;
}
.alert-node .alert-link {
  color: #223320;
}

.alert-gitlab {
  color: #782b15;
  background-color: #faddd4;
  border-color: #f8cfc3;
}
.alert-gitlab hr {
  border-top-color: #f5bdac;
}
.alert-gitlab .alert-link {
  color: #4d1b0d;
}

.alert-instagram {
  color: #213b51;
  background-color: #d9e3eb;
  border-color: #c9d8e3;
}
.alert-instagram hr {
  border-top-color: #b8ccda;
}
.alert-instagram .alert-link {
  color: #12212d;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #1377c9;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #353535;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid #efefef;
}
.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #1377c9;
  border-color: #1377c9;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0;
}
.list-group-flush .list-group-item:first-child {
  border-top-width: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #0a3e69;
  background-color: #bdd9f0;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #0a3e69;
  background-color: #a8cdeb;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #0a3e69;
  border-color: #0a3e69;
}

.list-group-item-secondary {
  color: #14181c;
  background-color: #c2c5c7;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #14181c;
  background-color: #b5b8bb;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #14181c;
  border-color: #14181c;
}

.list-group-item-success {
  color: #0c5c30;
  background-color: #bee9d1;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #0c5c30;
  background-color: #abe3c4;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0c5c30;
  border-color: #0c5c30;
}

.list-group-item-info {
  color: #0a6469;
  background-color: #bdedf0;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0a6469;
  background-color: #a8e7eb;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0a6469;
  border-color: #0a6469;
}

.list-group-item-warning {
  color: #7d6928;
  background-color: #fbf0cd;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #7d6928;
  background-color: #f9e9b5;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #7d6928;
  border-color: #7d6928;
}

.list-group-item-danger {
  color: #611b1b;
  background-color: #ecc6c6;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #611b1b;
  background-color: #e6b3b3;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #611b1b;
  border-color: #611b1b;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.list-group-item-blue {
  color: #1c4677;
  background-color: #c7ddf7;
}
.list-group-item-blue.list-group-item-action:hover, .list-group-item-blue.list-group-item-action:focus {
  color: #1c4677;
  background-color: #b1cff4;
}
.list-group-item-blue.list-group-item-action.active {
  color: #fff;
  background-color: #1c4677;
  border-color: #1c4677;
}

.list-group-item-teal {
  color: #10544b;
  background-color: #c0e5e0;
}
.list-group-item-teal.list-group-item-action:hover, .list-group-item-teal.list-group-item-action:focus {
  color: #10544b;
  background-color: #aeded7;
}
.list-group-item-teal.list-group-item-action.active {
  color: #fff;
  background-color: #10544b;
  border-color: #10544b;
}

.list-group-item-purple {
  color: #2a3366;
  background-color: #ced3ef;
}
.list-group-item-purple.list-group-item-action:hover, .list-group-item-purple.list-group-item-action:focus {
  color: #2a3366;
  background-color: #bbc2e9;
}
.list-group-item-purple.list-group-item-action.active {
  color: #fff;
  background-color: #2a3366;
  border-color: #2a3366;
}

.list-group-item-dark-gray {
  color: #161616;
  background-color: #c3c3c3;
}
.list-group-item-dark-gray.list-group-item-action:hover, .list-group-item-dark-gray.list-group-item-action:focus {
  color: #161616;
  background-color: #b6b6b6;
}
.list-group-item-dark-gray.list-group-item-action.active {
  color: #fff;
  background-color: #161616;
  border-color: #161616;
}

.list-group-item-light-gray {
  color: rgba(0, 0, 0, 0.6048);
  background-color: rgba(242, 242, 242, 0.7872);
}
.list-group-item-light-gray.list-group-item-action:hover, .list-group-item-light-gray.list-group-item-action:focus {
  color: rgba(0, 0, 0, 0.6048);
  background-color: rgba(229, 229, 229, 0.7872);
}
.list-group-item-light-gray.list-group-item-action.active {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6048);
  border-color: rgba(0, 0, 0, 0.6048);
}

.list-group-item-muted-light {
  color: #727477;
  background-color: #f5f6f7;
}
.list-group-item-muted-light.list-group-item-action:hover, .list-group-item-muted-light.list-group-item-action:focus {
  color: #727477;
  background-color: #e7e9ec;
}
.list-group-item-muted-light.list-group-item-action.active {
  color: #fff;
  background-color: #727477;
  border-color: #727477;
}

.list-group-item-facebook {
  color: #1f2e4f;
  background-color: #c8d1e2;
}
.list-group-item-facebook.list-group-item-action:hover, .list-group-item-facebook.list-group-item-action:focus {
  color: #1f2e4f;
  background-color: #b7c3d9;
}
.list-group-item-facebook.list-group-item-action.active {
  color: #fff;
  background-color: #1f2e4f;
  border-color: #1f2e4f;
}

.list-group-item-twitter {
  color: #00597b;
  background-color: #b8e8fa;
}
.list-group-item-twitter.list-group-item-action:hover, .list-group-item-twitter.list-group-item-action:focus {
  color: #00597b;
  background-color: #a0e0f8;
}
.list-group-item-twitter.list-group-item-action.active {
  color: #fff;
  background-color: #00597b;
  border-color: #00597b;
}

.list-group-item-vuejs {
  color: #226044;
  background-color: #caebdc;
}
.list-group-item-vuejs.list-group-item-action:hover, .list-group-item-vuejs.list-group-item-action:focus {
  color: #226044;
  background-color: #b7e4d0;
}
.list-group-item-vuejs.list-group-item-action.active {
  color: #fff;
  background-color: #226044;
  border-color: #226044;
}

.list-group-item-angular {
  color: #6e1818;
  background-color: #f3c5c5;
}
.list-group-item-angular.list-group-item-action:hover, .list-group-item-angular.list-group-item-action:focus {
  color: #6e1818;
  background-color: #efb0b0;
}
.list-group-item-angular.list-group-item-action.active {
  color: #fff;
  background-color: #6e1818;
  border-color: #6e1818;
}

.list-group-item-gulp {
  color: #6c2425;
  background-color: #f2cbcb;
}
.list-group-item-gulp.list-group-item-action:hover, .list-group-item-gulp.list-group-item-action:focus {
  color: #6c2425;
  background-color: #edb7b7;
}
.list-group-item-gulp.list-group-item-action.active {
  color: #fff;
  background-color: #6c2425;
  border-color: #6c2425;
}

.list-group-item-node {
  color: #375333;
  background-color: #d5e4d3;
}
.list-group-item-node.list-group-item-action:hover, .list-group-item-node.list-group-item-action:focus {
  color: #375333;
  background-color: #c6dac3;
}
.list-group-item-node.list-group-item-action.active {
  color: #fff;
  background-color: #375333;
  border-color: #375333;
}

.list-group-item-gitlab {
  color: #782b15;
  background-color: #f8cfc3;
}
.list-group-item-gitlab.list-group-item-action:hover, .list-group-item-gitlab.list-group-item-action:focus {
  color: #782b15;
  background-color: #f5bdac;
}
.list-group-item-gitlab.list-group-item-action.active {
  color: #fff;
  background-color: #782b15;
  border-color: #782b15;
}

.list-group-item-instagram {
  color: #213b51;
  background-color: #c9d8e3;
}
.list-group-item-instagram.list-group-item-action:hover, .list-group-item-instagram.list-group-item-action:focus {
  color: #213b51;
  background-color: #b8ccda;
}
.list-group-item-instagram.list-group-item-action.active {
  color: #fff;
  background-color: #213b51;
  border-color: #213b51;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #efefef;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #efefef;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #353535;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #1377c9 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0f5b9a !important;
}

.bg-secondary {
  background-color: #262f36 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #111518 !important;
}

.bg-success {
  background-color: #17b15c !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #118445 !important;
}

.bg-info {
  background-color: #13C0C9 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #0f939a !important;
}

.bg-warning {
  background-color: #f0ca4d !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ecbc1e !important;
}

.bg-danger {
  background-color: #bb3434 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #932929 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-blue {
  background-color: #3687e4 !important;
}

a.bg-blue:hover, a.bg-blue:focus,
button.bg-blue:hover,
button.bg-blue:focus {
  background-color: #1b6dcc !important;
}

.bg-teal {
  background-color: #1ea190 !important;
}

a.bg-teal:hover, a.bg-teal:focus,
button.bg-teal:hover,
button.bg-teal:focus {
  background-color: #16766a !important;
}

.bg-purple {
  background-color: #5163C5 !important;
}

a.bg-purple:hover, a.bg-purple:focus,
button.bg-purple:hover,
button.bg-purple:focus {
  background-color: #394aaa !important;
}

.bg-dark-gray {
  background-color: #2A2A2A !important;
}

a.bg-dark-gray:hover, a.bg-dark-gray:focus,
button.bg-dark-gray:hover,
button.bg-dark-gray:focus {
  background-color: #111111 !important;
}

.bg-light-gray {
  background-color: rgba(0, 0, 0, 0.24) !important;
}

a.bg-light-gray:hover, a.bg-light-gray:focus,
button.bg-light-gray:hover,
button.bg-light-gray:focus {
  background-color: rgba(0, 0, 0, 0.24) !important;
}

.bg-muted-light {
  background-color: #DBDFE4 !important;
}

a.bg-muted-light:hover, a.bg-muted-light:focus,
button.bg-muted-light:hover,
button.bg-muted-light:focus {
  background-color: #bec5ce !important;
}

.bg-facebook {
  background-color: #3b5998 !important;
}

a.bg-facebook:hover, a.bg-facebook:focus,
button.bg-facebook:hover,
button.bg-facebook:focus {
  background-color: #2d4373 !important;
}

.bg-twitter {
  background-color: #00aced !important;
}

a.bg-twitter:hover, a.bg-twitter:focus,
button.bg-twitter:hover,
button.bg-twitter:focus {
  background-color: #0087ba !important;
}

.bg-vuejs {
  background-color: #42b883 !important;
}

a.bg-vuejs:hover, a.bg-vuejs:focus,
button.bg-vuejs:hover,
button.bg-vuejs:focus {
  background-color: #359268 !important;
}

.bg-angular {
  background-color: #D32F2F !important;
}

a.bg-angular:hover, a.bg-angular:focus,
button.bg-angular:hover,
button.bg-angular:focus {
  background-color: #ab2424 !important;
}

.bg-gulp {
  background-color: #cf4647 !important;
}

a.bg-gulp:hover, a.bg-gulp:focus,
button.bg-gulp:hover,
button.bg-gulp:focus {
  background-color: #b32f30 !important;
}

.bg-node {
  background-color: #699f63 !important;
}

a.bg-node:hover, a.bg-node:focus,
button.bg-node:hover,
button.bg-node:focus {
  background-color: #54804f !important;
}

.bg-gitlab {
  background-color: #e65328 !important;
}

a.bg-gitlab:hover, a.bg-gitlab:focus,
button.bg-gitlab:hover,
button.bg-gitlab:focus {
  background-color: #c43e17 !important;
}

.bg-instagram {
  background-color: #3f729b !important;
}

a.bg-instagram:hover, a.bg-instagram:focus,
button.bg-instagram:hover,
button.bg-instagram:focus {
  background-color: #305777 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #efefef !important;
}

.border-top {
  border-top: 1px solid #efefef !important;
}

.border-right {
  border-right: 1px solid #efefef !important;
}

.border-bottom {
  border-bottom: 1px solid #efefef !important;
}

.border-left {
  border-left: 1px solid #efefef !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #1377c9 !important;
}

.border-secondary {
  border-color: #262f36 !important;
}

.border-success {
  border-color: #17b15c !important;
}

.border-info {
  border-color: #13C0C9 !important;
}

.border-warning {
  border-color: #f0ca4d !important;
}

.border-danger {
  border-color: #bb3434 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-blue {
  border-color: #3687e4 !important;
}

.border-teal {
  border-color: #1ea190 !important;
}

.border-purple {
  border-color: #5163C5 !important;
}

.border-dark-gray {
  border-color: #2A2A2A !important;
}

.border-light-gray {
  border-color: rgba(0, 0, 0, 0.24) !important;
}

.border-muted-light {
  border-color: #DBDFE4 !important;
}

.border-facebook {
  border-color: #3b5998 !important;
}

.border-twitter {
  border-color: #00aced !important;
}

.border-vuejs {
  border-color: #42b883 !important;
}

.border-angular {
  border-color: #D32F2F !important;
}

.border-gulp {
  border-color: #cf4647 !important;
}

.border-node {
  border-color: #699f63 !important;
}

.border-gitlab {
  border-color: #e65328 !important;
}

.border-instagram {
  border-color: #3f729b !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #1377c9 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0c4e83 !important;
}

.text-secondary {
  color: #262f36 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #070809 !important;
}

.text-success {
  color: #17b15c !important;
}

a.text-success:hover, a.text-success:focus {
  color: #0e6d39 !important;
}

.text-info {
  color: #13C0C9 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0c7d83 !important;
}

.text-warning {
  color: #f0ca4d !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #deae13 !important;
}

.text-danger {
  color: #bb3434 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #7f2323 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-blue {
  color: #3687e4 !important;
}

a.text-blue:hover, a.text-blue:focus {
  color: #1861b5 !important;
}

.text-teal {
  color: #1ea190 !important;
}

a.text-teal:hover, a.text-teal:focus {
  color: #126156 !important;
}

.text-purple {
  color: #5163C5 !important;
}

a.text-purple:hover, a.text-purple:focus {
  color: #324297 !important;
}

.text-dark-gray {
  color: #2A2A2A !important;
}

a.text-dark-gray:hover, a.text-dark-gray:focus {
  color: #040404 !important;
}

.text-light-gray {
  color: rgba(0, 0, 0, 0.24) !important;
}

a.text-light-gray:hover, a.text-light-gray:focus {
  color: rgba(0, 0, 0, 0.24) !important;
}

.text-muted-light {
  color: #DBDFE4 !important;
}

a.text-muted-light:hover, a.text-muted-light:focus {
  color: #afb8c3 !important;
}

.text-facebook {
  color: #3b5998 !important;
}

a.text-facebook:hover, a.text-facebook:focus {
  color: #263961 !important;
}

.text-twitter {
  color: #00aced !important;
}

a.text-twitter:hover, a.text-twitter:focus {
  color: #0074a1 !important;
}

.text-vuejs {
  color: #42b883 !important;
}

a.text-vuejs:hover, a.text-vuejs:focus {
  color: #2e805b !important;
}

.text-angular {
  color: #D32F2F !important;
}

a.text-angular:hover, a.text-angular:focus {
  color: #962020 !important;
}

.text-gulp {
  color: #cf4647 !important;
}

a.text-gulp:hover, a.text-gulp:focus {
  color: #9f292a !important;
}

.text-node {
  color: #699f63 !important;
}

a.text-node:hover, a.text-node:focus {
  color: #497045 !important;
}

.text-gitlab {
  color: #e65328 !important;
}

a.text-gitlab:hover, a.text-gitlab:focus {
  color: #ad3714 !important;
}

.text-instagram {
  color: #3f729b !important;
}

a.text-instagram:hover, a.text-instagram:focus {
  color: #294a65 !important;
}

.text-body {
  color: #353535 !important;
}

.text-muted {
  color: rgba(0, 0, 0, 0.54) !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #efefef;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #efefef;
  }
}
.mdk-header {
  position: relative;
  z-index: 1;
  margin-bottom: 1.25rem;
  height: 200px;
}
.mdk-header :last-child {
  margin-bottom: 0;
}
.mdk-header[data-condenses], .mdk-header[data-reveals] {
  will-change: transform;
}
.mdk-header [data-fx-condenses] {
  transform-origin: left top;
  opacity: 0;
}
.mdk-header [data-fx-condenses][data-fx-id] {
  opacity: 1;
}
.mdk-header [data-primary] {
  z-index: 1;
}

.mdk-header__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

[data-effects*=parallax-background] .mdk-header__bg {
  visibility: hidden;
}

.mdk-header__bg-rear,
.mdk-header__bg-front {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-size: cover;
}

.mdk-header__bg-rear {
  opacity: 0;
}

.mdk-header__content {
  position: relative;
  width: 100%;
  height: 100%;
}

.mdk-header[data-condenses] .mdk-header__content {
  z-index: 0;
}
.mdk-header[data-condenses] [data-primary] {
  z-index: 1;
}

.mdk-header--shadow::after {
  position: absolute;
  right: 0;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 5px;
  content: "";
  pointer-events: none;
  opacity: 0;
  box-shadow: inset 0px 5px 6px -3px rgba(0, 0, 0, 0.4);
  will-change: opacity;
  z-index: -1;
}

.mdk-header--shadow-show::after {
  opacity: 1;
}

.mdk-header--fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  margin-bottom: 0;
}

.mdk-header[disabled], .mdk-header[disabled]::after,
.mdk-header[disabled] .mdk-header__bg-rear,
.mdk-header[disabled] .mdk-header__bg-front,
.mdk-header[disabled] .mdk-header__content > [data-primary] {
  transition: none !important;
}

.mdk-header-layout {
  height: 100%;
  position: relative;
  overflow: hidden;
  z-index: 0;
}
.mdk-header-layout--fullbleed {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.mdk-header-layout > .mdk-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.mdk-header-layout[data-fullbleed] > .mdk-header, .mdk-header-layout[data-has-scrolling-region] > .mdk-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.mdk-header-layout__content {
  position: relative;
  z-index: 0;
}
.mdk-header-layout__content--fullbleed {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.mdk-header-layout__content--scrollable {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.mdk-box {
  position: relative;
  margin-bottom: 1.25rem;
}

.mdk-box__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  height: 100%;
}

[data-effects*=parallax-background] .mdk-box__bg {
  visibility: hidden;
}

.mdk-box__bg-rear,
.mdk-box__bg-front {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-size: cover;
}

.mdk-box__bg-rear {
  opacity: 0;
}

.mdk-box__content {
  position: relative;
  width: 100%;
  height: 100%;
}

.mdk-box[disabled], .mdk-box[disabled]::after,
.mdk-box[disabled] .mdk-box__bg-rear,
.mdk-box[disabled] .mdk-box__bg-front,
.mdk-box[disabled] .mdk-box__content > [data-primary] {
  transition: none !important;
}

.mdk-drawer {
  position: relative;
  visibility: hidden;
}
.mdk-drawer[data-opened], .mdk-drawer[data-closing] {
  visibility: visible;
}
.mdk-drawer[data-persistent] {
  width: 250px;
}
.mdk-drawer[data-persistent][data-position=left] {
  /*!rtl:ignore*/
  right: auto;
}
.mdk-drawer[data-persistent][data-position=right] {
  /*!rtl:ignore*/
  left: auto;
}

.mdk-drawer__content {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 250px;
  transition: transform 0.2s ease;
  background-color: #fff;
  /*!rtl:begin:ignore*/
  left: 0;
  transform: translate3d(-100%, 0, 0);
  /*!rtl:end:ignore*/
}
[data-position=right] > .mdk-drawer__content {
  /*!rtl:begin:ignore*/
  right: 0;
  left: auto;
  transform: translate3d(100%, 0, 0);
  /*!rtl:end:ignore*/
}
[data-opened] > .mdk-drawer__content {
  transform: translate3d(0, 0, 0);
}

.mdk-drawer__scrim {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: opacity 0.2s ease;
  transform: translateZ(0);
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
[data-opened] > .mdk-drawer__scrim {
  opacity: 1;
}
[data-persistent] > .mdk-drawer__scrim {
  visibility: hidden;
  opacity: 0;
}

.mdk-drawer-layout {
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
}
.mdk-drawer-layout--fullbleed {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.mdk-drawer-layout .mdk-drawer {
  position: relative;
}
.mdk-drawer-layout .mdk-drawer[data-persistent] {
  width: 250px;
}
.mdk-drawer-layout .mdk-drawer[data-persistent][data-position=left] {
  /*!rtl:remove*/
  order: 0;
  /*rtl:raw:
  order: 2;
  */
}
.mdk-drawer-layout .mdk-drawer[data-persistent][data-position=right] {
  /*!rtl:remove*/
  order: 2;
  /*rtl:raw:
  order: 0;
  */
}

.mdk-drawer-layout__content {
  position: relative;
  height: 100%;
  transition: transform 0.2s;
  min-width: 320px;
  z-index: 0;
  order: 1;
  flex: 1 1 0%;
}
.mdk-drawer-layout__content--scrollable {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.mdk-reveal {
  z-index: 0;
  position: relative;
  overflow: hidden;
  display: block;
}
.mdk-reveal[data-opened] .mdk-reveal__partial, .mdk-reveal--hover:hover .mdk-reveal__partial {
  opacity: 0;
}

.mdk-reveal__content {
  transition: transform 0.2s;
  background-color: #ffffff;
  position: relative;
  z-index: 0;
}

.mdk-reveal__partial {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  content: "";
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.95) 80%, white 100%);
  opacity: 1;
  z-index: 1;
  transition: 0.2s opacity;
  pointer-events: none;
}

.mdk-carousel__content {
  transition: transform 0.4s;
  transform: translate3d(0, 0, 0);
  touch-action: pan-x;
}

.mdk-carousel__item {
  float: left;
  user-select: none;
  cursor: move;
  cursor: grab;
  -webkit-touch-callout: none;
  width: 100%;
}

.mdk-tooltip {
  transform: scale(0);
  transition: transform 0.2s;
  transform-origin: top center;
  will-change: transform;
  display: inline-block;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 13px;
  z-index: 999;
  font-weight: 500;
  text-align: center;
  position: fixed;
  line-height: 1;
  max-width: 200px;
  padding: 0.4rem;
  border-radius: 0.25rem;
  pointer-events: none;
}
.mdk-tooltip[data-opened] {
  transform: scale(1);
}

/*-----------------------------------------------------------------*/
/*------------------------------------------------------------------
2. App
-------------------------------------------------------------------*/
.flex {
  flex: 1 1 0% !important;
}

.flex-none,
.flex-0 {
  flex: none !important;
}

.flex-grow {
  flex: 1 1 auto !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

@media (min-width: 576px) {
  .flex-sm {
    flex: 1 1 0% !important;
  }

  .flex-sm-none,
.flex-sm-0 {
    flex: none !important;
  }

  .flex-sm-grow {
    flex: 1 1 auto !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
}
@media (min-width: 768px) {
  .flex-md {
    flex: 1 1 0% !important;
  }

  .flex-md-none,
.flex-md-0 {
    flex: none !important;
  }

  .flex-md-grow {
    flex: 1 1 auto !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
}
@media (min-width: 992px) {
  .flex-lg {
    flex: 1 1 0% !important;
  }

  .flex-lg-none,
.flex-lg-0 {
    flex: none !important;
  }

  .flex-lg-grow {
    flex: 1 1 auto !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl {
    flex: 1 1 0% !important;
  }

  .flex-xl-none,
.flex-xl-0 {
    flex: none !important;
  }

  .flex-xl-grow {
    flex: 1 1 auto !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
}
.material-icons {
  vertical-align: middle;
}

.icon-muted {
  color: rgba(55, 77, 103, 0.2) !important;
}

a.icon-muted:hover .material-icons {
  color: #1377c9;
}

.icon-light {
  opacity: 0.24;
}

.icon-16pt {
  font-size: 1rem !important;
}

.icon-20pt {
  font-size: 1.25rem !important;
}

.icon-30pt {
  font-size: 30px !important;
}

.icon-40pt {
  font-size: 40px !important;
}

.icon-60pt {
  font-size: 60px !important;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  line-height: 1.25rem;
}

b,
strong {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

h1, .h1 {
  font-weight: 600;
}

.text-15pt {
  font-size: 0.9375rem !important;
}

.decoration-0:hover {
  text-decoration: none;
}

.link-date {
  color: #3687e4;
  position: relative;
  overflow: hidden;
  background: rgba(19, 119, 201, 0.05);
  padding: 2px 4px;
  border-radius: 2px;
}
.link-date::after {
  content: "................................................................";
  color: rgba(42, 42, 42, 0.4);
  font-size: 0.75rem;
  position: absolute;
  left: 0;
  overflow: hidden;
  width: 100%;
  top: 0.525rem;
  white-space: nowrap;
}
.link-date:hover {
  text-decoration: none;
}

.text-amount {
  font-size: 30px;
  letter-spacing: 2.1px;
  color: #112B4A;
  line-height: 2.1875rem;
}

.text-stats {
  font-weight: bold;
  letter-spacing: 0.93px;
  font-size: 0.8125rem;
  display: flex;
  align-items: center;
}
.text-stats .material-icons {
  margin-left: 0.125rem;
  font-size: 1rem;
}

#footer .text-white a {
  color: #ffffff;
}
#footer .text-white .text-light {
  color: rgba(255, 255, 255, 0.54) !important;
}
#footer .text-white .text-lighter {
  color: rgba(255, 255, 255, 0.24) !important;
}

html,
body {
  height: 100%;
  position: relative;
}

.mdk-header-layout,
.mdk-drawer-layout,
.mdk-drawer-layout__content {
  height: initial;
  min-height: 100%;
}

.layout-default .page {
  padding-bottom: 30px;
}

@media (min-width: 1200px) {
  .layout-mini .page__container, .layout-default .page__container, .layout-fluid .page__container,
.layout-mini .page__heading-container,
.layout-default .page__heading-container,
.layout-fluid .page__heading-container {
    padding-left: 30px;
    padding-right: 30px;
  }
}
.layout-default .page__container,
.layout-default .page__heading-container {
  max-width: 1300px;
}

.layout-fluid .page .page__container,
.layout-fluid .page .page__heading-container {
  max-width: 1300px;
}

.page__heading {
  padding-top: 15px;
  padding-bottom: 15px;
}
.page__heading h1 {
  line-height: 1;
}
@media (min-width: 1200px) {
  .page__heading:not(.page__heading--xl_small) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.layout-default .page__heading, .layout-fixed .page__heading, .layout-mini .page__heading {
  border-bottom: 1px solid #efefef;
  margin-bottom: 15px;
}
@media (min-width: 1200px) {
  .layout-default .page__heading:not(.page__heading--xl_small), .layout-fixed .page__heading:not(.page__heading--xl_small), .layout-mini .page__heading:not(.page__heading--xl_small) {
    margin-bottom: 30px;
  }
}

.page__header {
  background: white;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.12) !important;
  margin-bottom: 15px;
  z-index: 3;
  position: relative;
}
@media (min-width: 1200px) {
  .page__header {
    margin-bottom: 30px;
  }
}
.page__header + .page__header {
  z-index: 2;
}

.layout-sticky-subnav .mdk-header-layout {
  overflow: initial;
}
.layout-sticky-subnav .page__header-nav {
  position: sticky;
  top: 60px;
  z-index: 1;
}
.layout-sticky-subnav .page__header.flush-shadow {
  margin-bottom: 0;
  box-shadow: none !important;
}
.layout-sticky-subnav.layout-fixed .page__header:first-child {
  box-shadow: inherit;
}
.layout-sticky-subnav .page__container {
  z-index: 0;
  position: relative;
}

.mdk-header--shadow::after {
  box-shadow: 0 5px 15px 0 rgba(227, 227, 227, 0.5);
  bottom: 0;
  height: 60px;
}

.projects-item {
  margin-bottom: 0 !important;
  padding-bottom: 0.4375rem;
  border-left: 1px dotted rgba(55, 77, 103, 0.2);
  margin-left: 10px;
}

.bottom-spacing {
  margin-bottom: 15px;
}
@media (min-width: 992px) {
  .bottom-spacing {
    margin-bottom: 30px;
  }
}

.home-banner {
  width: 100%;
  height: 200px;
  display: block;
  position: relative;
}

.home-banner {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../images/home_banner.jpg");
  background-size: cover;
  min-height: 250px;
  height: 50vh;
}
.home-banner .page__container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
}

#footer {
  margin-top: 15px 0;
  padding: 30px;
}
#footer .brand {
  font-weight: bold;
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.54);
}
#footer .brand:hover {
  color: #ffffff;
  text-decoration: none;
}
#footer h5 {
  margin-bottom: 15px;
  font-weight: bold;
}
#footer ul.list-group-flush li {
  list-style: none;
  padding: 8px 0;
}
#footer ul.list-group-flush li a {
  color: rgba(255, 255, 255, 0.54);
}
#footer ul.list-group-flush li a:hover {
  color: #ffffff;
}

.dropdown-toggle:focus {
  outline: 0;
}

[data-caret=false]::before, [data-caret=false]::after {
  display: none;
}

.dropdown-menu {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, margin-top 0.3s ease, visibility 0.3s ease;
  margin-top: 20px !important;
  background-clip: initial;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
}
.dropdown-menu::before, .dropdown-menu::after {
  border: 8px solid transparent;
  border-bottom-color: #fff;
  content: "";
  height: 0;
  left: 10px;
  opacity: 0;
  transition: 0.1s opacity cubic-bezier(0.3, 0.5, 0.5, 1);
  position: absolute;
  top: -16px;
  width: 1px;
}
.dropdown-menu::before {
  top: -17px;
  border-bottom-color: #efefef;
}

.dropdown-menu-right::before, .dropdown-menu-right::after {
  left: initial;
  right: 10px;
}

.dropup .dropdown-menu {
  transform-origin: 10% bottom;
}
.dropup .dropdown-menu::before, .dropup .dropdown-menu::after {
  top: auto;
  bottom: -16px;
  border-bottom-color: transparent;
  border-top-color: #fff;
}
.dropup .dropdown-menu::before {
  bottom: -17px;
  border-top-color: #efefef;
}
.dropup .dropdown-menu-right {
  transform-origin: 90% bottom;
}

.show > .dropdown-menu,
.dropdown-menu.show {
  visibility: visible;
  opacity: 1;
  margin-top: 0.125rem !important;
}
.show > .dropdown-menu::before, .show > .dropdown-menu::after,
.dropdown-menu.show::before,
.dropdown-menu.show::after {
  opacity: 1;
}

.dropdown-menu-caret-center {
  transform-origin: 50% top;
}
.dropup .dropdown-menu-caret-center {
  transform-origin: 50% bottom;
}
.dropdown-menu-caret-center::before, .dropdown-menu-caret-center::after {
  left: 50%;
  margin-left: -4px;
}

.mdk-header {
  margin-bottom: 1rem;
  height: auto;
}
.mdk-header__content {
  display: flex;
  flex-direction: column;
}

.search-form {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #efefef;
  border-radius: 0.25rem;
}
.search-form .form-control {
  border-color: transparent;
  background-color: transparent;
  box-shadow: none;
}
.search-form .form-control:focus {
  border-color: transparent;
  box-shadow: none;
}
.search-form:not([class^=input-group-]) .btn {
  padding: 0 0.5rem;
}
.search-form .btn + .form-control {
  padding-left: 0;
}

.search-form--light .btn {
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
}
.search-form--light .form-control:focus + .btn {
  color: #495057;
}
.search-form--light .form-control:focus + .btn:hover {
  color: #353535;
}

.navbar {
  min-height: 60px;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 1rem;
}
.navbar .dropdown-menu {
  position: absolute;
}
@media (min-width: 576px) {
  .navbar.navbar-expand-sm .nav-item {
    height: 60px;
    display: flex;
    align-items: center;
  }
  .navbar.navbar-expand-sm .dropdown-menu {
    max-height: calc(100vh - 120px);
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
@media (min-width: 768px) {
  .navbar.navbar-expand-md .nav-item {
    height: 60px;
    display: flex;
    align-items: center;
  }
  .navbar.navbar-expand-md .dropdown-menu {
    max-height: calc(100vh - 120px);
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
@media (min-width: 992px) {
  .navbar.navbar-expand-lg .nav-item {
    height: 60px;
    display: flex;
    align-items: center;
  }
  .navbar.navbar-expand-lg .dropdown-menu {
    max-height: calc(100vh - 120px);
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
@media (min-width: 1200px) {
  .navbar.navbar-expand-xl .nav-item {
    height: 60px;
    display: flex;
    align-items: center;
  }
  .navbar.navbar-expand-xl .dropdown-menu {
    max-height: calc(100vh - 120px);
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
.navbar.navbar-expand .nav-item {
  height: 60px;
  display: flex;
  align-items: center;
}
.navbar.navbar-expand .dropdown-menu {
  max-height: calc(100vh - 120px);
  overflow-x: hidden;
  overflow-y: scroll;
}

.navbar-brand {
  font-size: 1.5rem;
  font-weight: 600;
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 1.25rem;
  display: flex;
  align-items: center;
}

.navbar-brand-icon {
  margin-right: 0.625rem;
}

.navbar-toggler {
  border: none;
  height: 60px;
  padding: 0;
  text-align: center;
  display: inline-block;
}

.navbar-toggler-right {
  order: 1;
}

.navbar-toggler-custom {
  display: flex;
  align-items: center;
}
.navbar-toggler-custom .material-icons {
  font-size: 1.875rem;
}

.navbar-height {
  height: 60px;
}

.navbar-nav .nav-link {
  font-weight: 600;
}

.navbar-toggler-dashboard {
  text-align: center;
  display: inline-block;
  padding: 0 1.25rem;
  height: 60px;
  border-radius: 0;
  font-weight: 600;
  font-size: 0.925rem;
}
.navbar-toggler-dashboard .material-icons {
  margin-right: 5px;
}

.navbar-circle-link .material-icons {
  font-size: 1.35rem;
  color: #ffffff;
}
.navbar-circle-link > span {
  width: 35px;
  height: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ml-navbar {
  margin-left: 0.625rem;
}

.mr-navbar {
  margin-right: 0.625rem;
}

.navbar-secondary {
  min-height: 60px;
}
@media (min-width: 576px) {
  .navbar-secondary.navbar-expand-sm .nav-item + .nav-item {
    margin-left: 1rem;
  }
  .navbar-secondary.navbar-expand-sm .navbar-nav,
.navbar-secondary.navbar-expand-sm .nav-item {
    display: flex;
    align-items: center;
  }
  .navbar-secondary.navbar-expand-sm .nav-item {
    height: 60px;
  }
}
@media (min-width: 768px) {
  .navbar-secondary.navbar-expand-md .nav-item + .nav-item {
    margin-left: 1rem;
  }
  .navbar-secondary.navbar-expand-md .navbar-nav,
.navbar-secondary.navbar-expand-md .nav-item {
    display: flex;
    align-items: center;
  }
  .navbar-secondary.navbar-expand-md .nav-item {
    height: 60px;
  }
}
@media (min-width: 992px) {
  .navbar-secondary.navbar-expand-lg .nav-item + .nav-item {
    margin-left: 1rem;
  }
  .navbar-secondary.navbar-expand-lg .navbar-nav,
.navbar-secondary.navbar-expand-lg .nav-item {
    display: flex;
    align-items: center;
  }
  .navbar-secondary.navbar-expand-lg .nav-item {
    height: 60px;
  }
}
@media (min-width: 1200px) {
  .navbar-secondary.navbar-expand-xl .nav-item + .nav-item {
    margin-left: 1rem;
  }
  .navbar-secondary.navbar-expand-xl .navbar-nav,
.navbar-secondary.navbar-expand-xl .nav-item {
    display: flex;
    align-items: center;
  }
  .navbar-secondary.navbar-expand-xl .nav-item {
    height: 60px;
  }
}
.navbar-secondary.navbar-expand .nav-item + .nav-item {
  margin-left: 1rem;
}
.navbar-secondary.navbar-expand .navbar-nav,
.navbar-secondary.navbar-expand .nav-item {
  display: flex;
  align-items: center;
}
.navbar-secondary.navbar-expand .nav-item {
  height: 60px;
}

.navbar-main.navbar-light {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
}
.navbar-main #account_menu {
  right: 10px;
  min-width: 200px;
}

.navbar .search-form {
  max-width: 250px;
  margin-right: 0.625rem;
}
.navbar .search-form .form-control {
  box-shadow: none;
}
.navbar .search-form .btn {
  background-color: transparent;
  box-shadow: none !important;
}

.navbar-light .search-form {
  background-color: #F6F6F6;
  border-color: transparent;
}
.navbar-light .search-form.highlight {
  border: 1px solid #efefef;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  background: #fff;
}

.navbar-dark .search-form.highlight input {
  color: #ffffff;
  font-weight: normal;
}
.navbar-dark .search-form.highlight .btn {
  color: #ffffff !important;
}

.navbar .badge-notifications {
  border: 2px solid #ffffff;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
}

.navbar-light .badge-notifications {
  border-color: #ffffff;
}

.navbar-dark .nav-link:hover, .navbar-dark .nav-link:hover .nav-icon {
  color: #fff;
}

.navbar-notifications-indicator {
  position: relative;
}
.navbar-notifications-indicator::after {
  width: 6px;
  height: 6px;
  content: "";
  background: #bb3434;
  position: absolute;
  top: -3px;
  right: 0px;
  border-radius: 100px;
}

.dropdown-menu.navbar-notifications-menu {
  width: 320px;
  min-height: 240px;
  line-height: 1.5;
  padding: 0;
}
.dropdown-menu.navbar-notifications-menu .dropdown-item {
  text-transform: none;
  letter-spacing: initial;
  font-size: 1rem;
  font-weight: normal;
  color: #353535;
}
.dropdown-menu.navbar-notifications-menu > .dropdown-item {
  background: #F9F9F9;
  border-bottom: 1px solid #efefef;
}
.dropdown-menu.navbar-notifications-menu .navbar-notifications-menu__title {
  letter-spacing: initial;
  font-weight: 600;
}
.dropdown-menu.navbar-notifications-menu .navbar-notifications-menu__content {
  max-height: 220px;
}
.dropdown-menu.navbar-notifications-menu .navbar-notifications-menu__content .dropdown-item {
  white-space: normal;
}
.dropdown-menu.navbar-notifications-menu .navbar-notifications-menu__footer {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top: 1px solid #efefef;
  font-weight: 600;
}
.dropdown-menu.navbar-notifications-menu .navbar-notifications-menu__footer:hover {
  color: #3687e4;
}

.navbar-company-menu {
  width: 250px;
  padding-top: 0;
  margin-right: 3px;
}
.navbar-company-menu.dropdown-menu-right {
  right: 10px !important;
}
.navbar-company-menu .navbar-company-info {
  background: #f9f9f9;
}
.navbar-company-menu .navbar-company-info + .dropdown-divider {
  margin-top: 0;
}

.navbar-dark [class*=border-] {
  border-color: rgba(255, 255, 255, 0.24) !important;
}
.navbar-dark .search-form {
  border-color: transparent;
}
.navbar-dark .search-form .form-control {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-dark .search-form {
  background-color: rgba(0, 0, 0, 0.24);
}
.navbar-dark .search-form .form-control::placeholder {
  color: rgba(255, 255, 255, 0.54);
  font-weight: 500;
}
.navbar-dark .search-form.highlight .form-control::placeholder {
  color: transparent;
}
.navbar-dark .search-form .btn {
  color: rgba(255, 255, 255, 0.54);
}
.navbar-dark .navbar-toggler {
  color: #ffffff;
}
.navbar-dark .navbar-toggler-custom .material-icons {
  color: white;
}
.navbar-dark .nav-icon,
.navbar-dark .navbar-notifications-indicator {
  color: rgba(255, 255, 255, 0.54);
}
.navbar-dark [data-toggle=sidebar] .nav-icon {
  color: white;
}
.navbar-dark .navbar-toggler-company > span {
  background-color: #1377c9;
}

.navbar-dark .navbar-circle-link span {
  background: #fff;
}

.navbar-light .navbar-circle-link span {
  background: #efefef;
  color: rgba(0, 0, 0, 0.54) !important;
}

.navbar-light .navbar-nav > .active {
  position: relative;
}
.navbar-light .navbar-nav > .active::before {
  content: "";
  background-color: #1377c9;
  height: 2px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.navbar-light .text-light {
  color: rgba(0, 0, 0, 0.9) !important;
}
.navbar-light .nav-icon {
  color: rgba(112, 112, 112, 0.24);
}
.navbar-light [data-toggle=sidebar] .nav-icon {
  color: rgba(0, 0, 0, 0.54);
}
.mdk-drawer__content {
  background: transparent;
}

.mdk-header-layout .mdk-drawer__content {
  top: 60px;
}

#events-drawer[data-persistent] {
  width: 300px;
}
#events-drawer .mdk-drawer__content {
  width: 300px;
}

.sidebar-p-a {
  padding: 18.75px 18.75px;
}

.sidebar-p-x {
  padding-left: 18.75px;
  padding-right: 18.75px;
}

.sidebar-p-y {
  padding-top: 18.75px;
  padding-bottom: 18.75px;
}

.sidebar-p-t {
  padding-top: 18.75px;
}

.sidebar-p-b {
  padding-bottom: 18.75px;
}

.sidebar-p-l {
  padding-left: 18.75px;
  padding-right: 18.75px;
}

.sidebar-p-r {
  padding-right: 18.75px;
}

.sidebar-m-a {
  margin: 18.75px 18.75px;
}

.sidebar-m-x {
  margin-left: 18.75px;
  margin-right: 18.75px;
}

.sidebar-m-y {
  margin-top: 18.75px;
  margin-bottom: 18.75px;
}

.sidebar-m-t {
  margin-top: 18.75px;
}

.sidebar-m-b {
  margin-bottom: 18.75px;
}

.sidebar-m-l {
  margin-left: 18.75px;
}

.sidebar-m-r {
  margin-right: 18.75px;
}

.sidebar-b-a {
  border: 1px solid transparent;
}

.sidebar-b-x {
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}

.sidebar-b-y {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}

.sidebar-b-t {
  border-top: 1px solid transparent;
}

.sidebar-b-b {
  border-bottom: 1px solid transparent;
}

.sidebar-block {
  padding-left: 18.75px;
  padding-right: 18.75px;
  margin-bottom: 18.75px;
}

.sidebar > p,
.sidebar > .sidebar-text {
  padding-left: 18.75px;
  padding-right: 18.75px;
  margin-bottom: 18.75px;
}

.sidebar-brand {
  font-weight: 500;
  font-size: 1.3rem;
  margin-bottom: 9.375px;
  display: flex;
  align-items: center;
}
.sidebar-brand:hover {
  text-decoration: none;
}

.sidebar-brand-icon {
  margin-right: 9.375px;
}

.sidebar-brand-header {
  height: 56px;
  line-height: 56px;
  padding-left: 18.75px;
  padding-right: 18.75px;
  margin-bottom: 18.75px;
  width: 100%;
}

.sidebar-brand-border {
  border-bottom: 1px solid transparent;
}

.sidebar-heading {
  font-weight: bold;
  font-size: 0.6875rem;
  font-family: inherit;
  text-transform: uppercase;
  letter-spacing: 0.92px;
  line-height: normal;
  padding-left: 18.75px;
  padding-right: 18.75px;
  margin-bottom: 18.75px;
}

.sidebar-badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.sidebar-light {
  color: rgba(0, 0, 0, 0.54);
  background: #ffffff;
}
.sidebar-light.sidebar-left {
  border-right-width: 0;
  border-right-style: solid;
  border-right-color: #e5e5e5;
}
.sidebar-light.sidebar-right {
  border-left-width: 0;
  border-left-style: solid;
  border-left-color: #e5e5e5;
}
.sidebar-light .sidebar-link {
  color: inherit;
}
.sidebar-light [class*=sidebar-b-] {
  border-color: #e5e5e5;
}
.sidebar-light .sidebar-text, .sidebar-light p {
  color: rgba(0, 0, 0, 0.54);
}
.sidebar-light .sidebar-heading, .sidebar-light .sidebar-heading > a {
  color: rgba(0, 0, 0, 0.34);
}
.sidebar-light hr {
  border-color: #e5e5e5;
}
.sidebar-light .sidebar-brand {
  color: #1377c9;
}
.sidebar-light .sidebar-brand-bg {
  background: rgba(0, 0, 0, 0.07);
}
.sidebar-light .sidebar-brand-border {
  border-color: #e5e5e5;
}
.sidebar-light .sidebar-menu-button {
  color: rgba(33, 33, 33, 0.84);
}
.sidebar-light .sidebar-menu-toggle-icon {
  color: rgba(33, 33, 33, 0.24);
}
.sidebar-light .sidebar-menu-icon {
  color: rgba(33, 33, 33, 0.24);
}
.sidebar-light .sidebar-menu-label {
  background: rgba(0, 0, 0, 0.3);
  color: #ffffff;
}
.sidebar-light .sidebar-menu-button:hover {
  color: #212121;
  background: transparent;
}
.sidebar-light .sidebar-menu-button:hover .sidebar-menu-icon {
  color: rgba(33, 33, 33, 0.84);
}
.sidebar-light .sidebar-menu-button:hover .sidebar-menu-toggle-icon {
  color: rgba(33, 33, 33, 0.84);
}
.sidebar-light .open {
  background: #f4f4f4;
}
.sidebar-light .open > .sidebar-menu-button .sidebar-menu-toggle-icon {
  color: rgba(0, 0, 0, 0.54);
}
.sidebar-light .open > .sidebar-menu-button {
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
}
.sidebar-light .open > .sidebar-menu-button .sidebar-menu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.sidebar-light .active > .sidebar-menu-button {
  color: #212121;
}
.sidebar-light .active > .sidebar-menu-button .sidebar-menu-icon {
  color: #1377c9;
}
.sidebar-light .sidebar-submenu .sidebar-menu-button {
  color: rgba(0, 0, 0, 0.54);
}
.sidebar-light .sidebar-submenu .sidebar-menu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.sidebar-light .sidebar-submenu .sidebar-menu-button:hover {
  color: #212121;
}
.sidebar-light .sidebar-submenu .sidebar-menu-button:hover .sidebar-menu-icon {
  color: #212121;
}
.sidebar-light .sidebar-submenu .active > .sidebar-menu-button {
  color: #212121;
}
.sidebar-light .sidebar-submenu .active > .sidebar-menu-button .sidebar-menu-icon {
  color: #212121;
}
.sidebar-light .sm-bordered {
  border-color: #e5e5e5;
}
.sidebar-light .sm-bordered.sidebar-submenu {
  border-color: #e5e5e5;
}
.sidebar-light .sm-item-bordered > .sidebar-menu-item {
  border-color: #e5e5e5;
}
.sidebar-light .sm-item-bordered.sidebar-submenu > .sidebar-menu-item {
  border-color: #e5e5e5;
}
.sidebar-light .sm-icons-block > .sidebar-menu-item > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(33, 33, 33, 0.24);
}
.sidebar-light .sm-icons-block > .sidebar-menu-item.open > .sidebar-menu-button .sidebar-menu-icon {
  color: rgba(0, 0, 0, 0.54);
  background: rgba(0, 0, 0, 0.12);
}
.sidebar-light .sm-icons-block > .sidebar-menu-item.active > .sidebar-menu-button .sidebar-menu-icon {
  background: #1377c9;
  color: #fff;
}
.sidebar-light .sm-active-button-bg > .active > .sidebar-menu-button {
  background: #1377c9;
  color: #fff;
}
.sidebar-light .sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-icon {
  color: #fff;
}
.sidebar-light .sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-toggle-icon {
  color: #fff;
}
.sidebar-light .sm-icons-block.sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-icon {
  background: #1377c9;
}

.sidebar-dark {
  color: rgba(255, 255, 255, 0.54);
  background: transparent;
}
.sidebar-dark.sidebar-left {
  border-right-width: 0;
  border-right-style: solid;
  border-right-color: rgba(0, 0, 0, 0.15);
}
.sidebar-dark.sidebar-right {
  border-left-width: 0;
  border-left-style: solid;
  border-left-color: rgba(0, 0, 0, 0.15);
}
.sidebar-dark .sidebar-link {
  color: #fff;
}
.sidebar-dark [class*=sidebar-b-] {
  border-color: rgba(255, 255, 255, 0.12);
}
.sidebar-dark .sidebar-text, .sidebar-dark p {
  color: rgba(255, 255, 255, 0.54);
}
.sidebar-dark .sidebar-heading, .sidebar-dark .sidebar-heading > a {
  color: rgba(255, 255, 255, 0.84);
}
.sidebar-dark hr {
  border-color: rgba(255, 255, 255, 0.12);
}
.sidebar-dark .sidebar-brand {
  color: #ffffff;
}
.sidebar-dark .sidebar-brand-bg {
  background: rgba(0, 0, 0, 0.12);
}
.sidebar-dark .sidebar-brand-border {
  border-color: rgba(255, 255, 255, 0.12);
}
.sidebar-dark .sidebar-menu-button {
  color: rgba(255, 255, 255, 0.54);
}
.sidebar-dark .sidebar-menu-toggle-icon {
  color: rgba(255, 255, 255, 0.54);
}
.sidebar-dark .sidebar-menu-icon {
  color: rgba(255, 255, 255, 0.24);
}
.sidebar-dark .sidebar-menu-button:hover {
  color: #ffffff;
  background: transparent;
}
.sidebar-dark .sidebar-menu-button:hover .sidebar-menu-icon {
  color: #ffffff;
}
.sidebar-dark .sidebar-menu-button:hover .sidebar-menu-toggle-icon {
  color: #ffffff;
}
.sidebar-dark .open {
  background: rgba(0, 0, 0, 0.24);
}
.sidebar-dark .open > .sidebar-menu-button .sidebar-menu-toggle-icon {
  color: rgba(255, 255, 255, 0.54);
}
.sidebar-dark .open > .sidebar-menu-button {
  background: transparent;
  color: rgba(255, 255, 255, 0.54);
}
.sidebar-dark .open > .sidebar-menu-button .sidebar-menu-icon {
  color: rgba(255, 255, 255, 0.54);
}
.sidebar-dark .active > .sidebar-menu-button {
  color: #ffffff;
}
.sidebar-dark .active > .sidebar-menu-button .sidebar-menu-icon {
  color: #ffffff;
}
.sidebar-dark .sidebar-submenu .sidebar-menu-button {
  color: rgba(255, 255, 255, 0.54);
}
.sidebar-dark .sidebar-submenu .sidebar-menu-icon {
  color: rgba(255, 255, 255, 0.54);
}
.sidebar-dark .sidebar-submenu .sidebar-menu-button:hover {
  color: #ffffff;
}
.sidebar-dark .sidebar-submenu .sidebar-menu-button:hover .sidebar-menu-icon {
  color: #ffffff;
}
.sidebar-dark .sidebar-submenu .active > .sidebar-menu-button {
  color: #ffffff;
}
.sidebar-dark .sidebar-submenu .active > .sidebar-menu-button .sidebar-menu-icon {
  color: #ffffff;
}
.sidebar-dark .sm-bordered {
  border-color: rgba(255, 255, 255, 0.12);
}
.sidebar-dark .sm-bordered.sidebar-submenu {
  border-color: rgba(255, 255, 255, 0.12);
}
.sidebar-dark .sm-item-bordered > .sidebar-menu-item {
  border-color: rgba(0, 0, 0, 0.24);
}
.sidebar-dark .sm-item-bordered.sidebar-submenu > .sidebar-menu-item {
  border-color: rgba(0, 0, 0, 0.24);
}
.sidebar-dark .sm-icons-block > .sidebar-menu-item > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(255, 255, 255, 0.24);
}
.sidebar-dark .sm-icons-block > .sidebar-menu-item.open > .sidebar-menu-button .sidebar-menu-icon {
  color: rgba(255, 255, 255, 0.54);
  background: rgba(0, 0, 0, 0.12);
}
.sidebar-dark .sm-icons-block > .sidebar-menu-item.active > .sidebar-menu-button .sidebar-menu-icon {
  background: transparent;
  color: #fff;
}
.sidebar-dark .sm-active-button-bg > .active > .sidebar-menu-button {
  background: transparent;
  color: #fff;
}
.sidebar-dark .sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-icon {
  color: #fff;
}
.sidebar-dark .sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-toggle-icon {
  color: #fff;
}
.sidebar-dark .sm-icons-block.sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-icon {
  background: transparent;
}

.sidebar-menu {
  margin-bottom: 18.75px;
  padding: 0 0;
  list-style: none;
}

.sidebar-menu-item {
  position: relative;
  overflow: hidden;
}

.sidebar-menu-button {
  display: flex;
  align-items: center;
  font-weight: 600;
  line-height: 42px;
  padding: 0 18.75px;
  font-size: 1rem;
}
.sidebar-menu-button, .sidebar-menu-button:hover, .sidebar-menu-button:focus {
  text-decoration: none;
}

.active > .sidebar-menu-button {
  font-weight: 600;
}

.sidebar-menu-toggle-icon {
  position: relative;
  font-size: 1rem;
}

.sidebar-menu-toggle-icon:before {
  font-family: "Material Icons";
  content: "";
  display: flex;
  align-items: center;
  font-size: inherit;
  color: inherit;
}

.open > .sidebar-menu-button .sidebar-menu-toggle-icon:before {
  content: "";
}

.sidebar-menu-icon {
  vertical-align: middle;
  font-size: 24px;
  display: inline-block;
  line-height: normal;
  position: relative;
}
.sidebar-menu-icon--left {
  margin-right: 1rem;
}
.sidebar-menu-icon--right {
  margin-left: 1rem;
}

.sidebar-menu-badge {
  padding: 0.2rem 0.3rem;
  font-size: 0.75rem;
}

.sidebar-menu-badge,
.sidebar-menu-toggle-icon {
  margin-left: 5px;
}

.sidebar-submenu {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
}
.open > .sidebar-submenu {
  display: block;
}
.sidebar-submenu .sidebar-menu-button {
  line-height: 1.6rem;
  font-size: 0.925rem;
}
.sidebar-submenu .sidebar-menu-icon {
  font-size: 24px;
}

.sm-icons-1 .sidebar-menu-icon {
  font-size: 1rem;
}

.sm-icons-small .sidebar-menu-icon {
  font-size: 0.875rem;
}

.sm-condensed > .sidebar-menu-item > .sidebar-menu-button {
  line-height: 31.5px;
}

.sm-condensed.sidebar-submenu > .sidebar-menu-item > .sidebar-menu-button {
  line-height: 1.2rem;
}

.sm-bordered {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.sm-item-bordered > .sidebar-menu-item {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.sm-item-bordered > .sidebar-menu-item:last-of-type {
  border-bottom: none;
}
.sm-item-bordered .sidebar-submenu {
  border-bottom: none;
}

.sm-icons-block > .sidebar-menu-item > .sidebar-menu-button .sidebar-menu-icon {
  text-align: center;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  width: 30px;
}

.sm-icons-block.sidebar-submenu > .sidebar-menu-item > .sidebar-menu-button .sidebar-menu-icon {
  height: 30px;
  line-height: 30px;
  width: 30px;
}

.sm-indent > .sidebar-menu-item .sidebar-menu-button {
  padding-left: 37.5px;
}

.sidebar {
  position: relative;
  height: 100%;
  text-align: initial;
  overscroll-behavior: none;
}

.simplebar-content {
  height: 100%;
  overflow-x: hidden !important;
}

.sidebar-account {
  height: 60px;
}
@media (min-width: 992px) {
  .sidebar-account {
    width: 250px;
  }
}
.sidebar-account svg#drawer-logo {
  fill: currentColor;
}
.sidebar-account .sidebar-brand {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1;
  margin: 0;
}
.sidebar-account small {
  font-size: 0.925rem;
}

.sidebar-light .sidebar-account {
  background: #1377c9;
}
.sidebar-light .sidebar-account a {
  color: rgba(255, 255, 255, 0.54);
}
.sidebar-light .sidebar-account .sidebar-brand {
  color: #fff;
}

.sidebar-dark .sidebar-account {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.sidebar-dark .sidebar-account a {
  color: #ffffff;
}
.sidebar-dark .sidebar-account small {
  color: rgba(255, 255, 255, 0.54);
}

.sidebar-heading {
  margin-bottom: 9.375px;
}

.nav-tabs.sidebar-tabs {
  padding: 0 calc(18.75px - .46875rem);
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.nav-tabs.sidebar-tabs .nav-link {
  padding: 0.6875rem 0.46875rem;
  font-size: 0.6875rem;
  line-height: 0.8125rem;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs.sidebar-tabs .nav-link.active,
.nav-tabs.sidebar-tabs .nav-item.show .nav-link {
  position: relative;
}
.nav-tabs.sidebar-tabs .nav-link.active::after,
.nav-tabs.sidebar-tabs .nav-item.show .nav-link::after {
  border: 6px solid transparent;
  content: "";
  height: 0;
  left: calc(50% - 6px);
  position: absolute;
  bottom: 0;
  width: 1px;
}

.sidebar .simplebar-content {
  display: flex;
  flex-direction: column;
}

.sidebar-menu {
  margin-top: 15px;
  margin-bottom: 15px;
}

.sidebar-heading + .sidebar-heading {
  margin-top: 2.25rem;
}

.sidebar-heading:first-child {
  margin-top: 1.25rem;
}

.sidebar-menu > .sidebar-menu-item > .sidebar-submenu > .sidebar-menu-item:last-child {
  margin-bottom: 1rem;
}

.sidebar-submenu .sidebar-menu-text {
  padding-left: 1rem;
  margin-left: 0.75rem;
}

.sidebar .progress {
  height: 7px;
}

.sidebar-stats {
  font-weight: 500;
}
.sidebar-stats__col {
  padding: 0.5625rem 0;
}
.sidebar-stats__title {
  font-size: 0.8125rem;
  line-height: 1rem;
}
.sidebar-stats__value {
  font-size: 1.125rem;
  line-height: 1.3125rem;
}

.sidebar-menu-toggle-icon {
  font-weight: normal;
}

.sidebar-submenu > .sidebar-menu-item .sidebar-menu-text {
  padding-left: calc(30px + 1.125rem);
  margin-left: 0;
}
.sidebar-submenu > .sidebar-menu-item .sidebar-menu-text:before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.54);
  display: inline-block;
  vertical-align: middle;
  left: calc(15px + 1.125rem);
  top: 50%;
  margin-top: -3px;
  position: absolute;
}

.sidebar-menu-item .sidebar-menu-icon {
  width: 30px;
  height: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-menu-item {
  padding-top: 2px;
  padding-bottom: 2px;
}

.sidebar-menu-item.active {
  background: rgba(19, 119, 201, 0.05);
}
.sidebar-menu-item.active:after {
  content: "";
  width: 3px;
  /* padding: 10px; */
  background: #1377c9;
  position: absolute;
  top: 0px;
  bottom: 0px;
}

.sidebar-dark .sidebar-menu-item.active > .sidebar-menu-button .sidebar-menu-text:before, .sidebar-dark .sidebar-menu-item:hover > .sidebar-menu-button .sidebar-menu-text:before {
  background: #ffffff;
}

.sidebar-light .sidebar-menu-item.active > .sidebar-menu-button .sidebar-menu-text:before, .sidebar-light .sidebar-menu-item:hover > .sidebar-menu-button .sidebar-menu-text:before {
  background: #1377c9;
}
.sidebar-light .sidebar-menu-item > .sidebar-menu-button .sidebar-menu-text:before {
  background: rgba(33, 33, 33, 0.84);
}

.sidebar {
  box-shadow: 0 0px 8px rgba(0, 0, 0, 0.12);
}

.bg-soft-primary {
  color: #1169b2;
  background-color: rgba(19, 119, 201, 0.1);
}

.bg-soft-secondary {
  color: #1c2227;
  background-color: rgba(38, 47, 54, 0.1);
}

.bg-soft-success {
  color: #149a50;
  background-color: rgba(23, 177, 92, 0.1);
}

.bg-soft-info {
  color: #11aab2;
  background-color: rgba(19, 192, 201, 0.1);
}

.bg-soft-warning {
  color: #eec335;
  background-color: rgba(240, 202, 77, 0.1);
}

.bg-soft-danger {
  color: #a72e2e;
  background-color: rgba(187, 52, 52, 0.1);
}

.bg-soft-light {
  color: #e9ecef;
  background-color: rgba(248, 249, 250, 0.1);
}

.bg-soft-dark {
  color: #292d32;
  background-color: rgba(52, 58, 64, 0.1);
}

.bg-soft-blue {
  color: #207ae1;
  background-color: rgba(54, 135, 228, 0.1);
}

.bg-soft-teal {
  color: #1a8c7d;
  background-color: rgba(30, 161, 144, 0.1);
}

.bg-soft-purple {
  color: #3f53bd;
  background-color: rgba(81, 99, 197, 0.1);
}

.bg-soft-dark-gray {
  color: #1d1d1d;
  background-color: rgba(42, 42, 42, 0.1);
}

.bg-soft-light-gray {
  color: rgba(0, 0, 0, 0.24);
  background-color: rgba(0, 0, 0, 0.1);
}

.bg-soft-muted-light {
  color: #ccd2d9;
  background-color: rgba(219, 223, 228, 0.1);
}

.bg-soft-facebook {
  color: #344e86;
  background-color: rgba(59, 89, 152, 0.1);
}

.bg-soft-twitter {
  color: #0099d4;
  background-color: rgba(0, 172, 237, 0.1);
}

.bg-soft-vuejs {
  color: #3ba576;
  background-color: rgba(66, 184, 131, 0.1);
}

.bg-soft-angular {
  color: #c02929;
  background-color: rgba(211, 47, 47, 0.1);
}

.bg-soft-gulp {
  color: #c83435;
  background-color: rgba(207, 70, 71, 0.1);
}

.bg-soft-node {
  color: #5e9059;
  background-color: rgba(105, 159, 99, 0.1);
}

.bg-soft-gitlab {
  color: #db4519;
  background-color: rgba(230, 83, 40, 0.1);
}

.bg-soft-instagram {
  color: #386589;
  background-color: rgba(63, 114, 155, 0.1);
}

.card {
  margin-bottom: 15px;
  border: none;
}
.card:not(.clear-shadow) {
  box-shadow: 0 10px 25px 0 rgba(50, 50, 93, 0.07), 0 5px 15px 0 rgba(0, 0, 0, 0.07);
}
@media (min-width: 992px) {
  .card {
    margin-bottom: 30px;
  }
}

.card-stats {
  padding: 1rem 1.25rem;
}

.card-margin {
  margin-bottom: 15px;
}
@media (min-width: 992px) {
  .card-margin {
    margin-bottom: 30px;
  }
}

.form-row .card.form-row__card {
  margin-bottom: 10px;
}

.card-list > .card:last-child {
  margin-bottom: 0;
}

@media (min-width: 576px) {
  .card-group {
    margin-bottom: 30px;
    box-shadow: 0 1px 0 0 rgba(229, 229, 229, 0.5);
    border-radius: 0.25rem;
  }
  .card-group > .card {
    box-shadow: none;
    border: 1px solid #efefef;
  }
}

.card-header {
  background-color: #F7F8F9;
}

.card-header__title {
  font-size: 1.125rem;
  font-weight: 600;
  font-family: "Lato", sans-serif;
  letter-spacing: 0;
}
.card-header__title:last-child {
  margin-bottom: 0;
}
.active > .card-header__title {
  color: #1377c9;
}

.card-header-large {
  padding-top: 1.4375rem;
  padding-bottom: 1.4375rem;
}

.card-header-sm {
  padding-top: 0.85rem;
  padding-bottom: 0.85rem;
}

.card-footer {
  background-color: white;
}

.card-body-lg {
  padding: 1.6875rem;
}

.card-body-x-lg {
  padding-left: 1.6875rem;
  padding-right: 1.6875rem;
}

.card__course .card-header-dark {
  vertical-align: middle;
}
.card__course .card-header-dark .card-header__title, .card__course .card-header-dark .card-header__title a {
  color: #ffffff;
}
.card__course .card-header-dark .card-header__title:hover, .card__course .card-header-dark .card-header__title a:hover {
  text-decoration: none;
}
.card__course .card-header-dark .card-header__title .course__title, .card__course .card-header-dark .card-header__title a .course__title {
  color: rgba(255, 255, 255, 0.54);
  display: block;
  font-size: 0.9rem;
  margin-bottom: 5px;
}
.card__course .card-header {
  position: relative;
  height: 140px;
  overflow: hidden;
  text-align: center;
}
.card__course .play-button {
  display: none;
}
.card__course .card-img-top {
  background: #000000;
  color: #1377c9;
}
.card__course .card-img-top svg {
  background: #fff;
  border-radius: 100%;
  padding: 2px;
}

.card__course.card__course__animate:hover {
  margin-top: -6px;
  transition: all 0.3s;
}
.card__course.card__course__animate:hover a.text-body {
  color: #1377c9 !important;
}
.card__course.card__course__animate:hover > a.card-img-top > img {
  opacity: 0.6;
  transition: opacity 0.6s;
}
.card__course.card__course__animate:hover .play-button {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 12;
}

.card-group-row {
  display: flex;
  flex-flow: row wrap;
}
.card-group-row__col {
  display: flex;
}
.card-group-row__card {
  flex: 1 0 0;
}

@media (min-width: 768px) {
  .card-margin-md-negative-40 {
    margin-top: -40px !important;
  }
}

.form-row .card.form-row__card {
  margin-bottom: 10px;
}

.card-list > .card:last-child {
  margin-bottom: 0;
}

.card-title > a:hover {
  color: #1377c9;
  text-decoration: none;
}
.card-title:last-child {
  margin-bottom: 0;
}

.card,
.card-group {
  margin-bottom: 1.5rem;
}

.card-2by1 .card-body {
  padding: 0.625rem 1.25rem;
}

.card-sm .card-body {
  padding: 0.625rem;
}

[class*=card-img] {
  position: relative;
  object-fit: cover;
}
@media (min-width: 576px) {
  [class*=card-img] {
    max-height: 200px;
  }
}
@media (min-width: 992px) {
  [class*=card-img] {
    overflow: hidden;
  }
}
[class*=card-img]::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(52, 58, 64, 0.125);
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 2;
  }
}
@media (min-width: 992px) {
  .card-columns--3 {
    column-count: 3;
  }
}
.card__options {
  position: absolute;
  right: 1.25rem;
  top: 0.625rem;
}

.card .media-left {
  padding-right: 0.625rem;
}
.card .media-right {
  padding-left: 0.625rem;
}

.table thead th {
  font-size: 0.825rem;
  color: rgba(55, 77, 103, 0.54);
  padding: 0.35rem 1rem;
}

.table tbody td {
  vertical-align: middle;
}

.thead-border-top-0 thead th {
  border-top: 0;
}

.table tbody .selected > td {
  background-color: rgba(88, 151, 224, 0.05);
}

.table .sort {
  color: inherit;
  display: flex-inline;
  align-items: center;
  text-decoration: none;
}
.table .sort::after {
  margin-left: 0.5rem;
  content: url("data:image/svg+xml;utf8,<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M3 0L6 4H0L3 0ZM3 10L0 6H6L3 10Z' fill='rgba(55, 77, 103, 0.54)'/></svg>");
}
.table .sort.asc, .table .sort.desc {
  color: #353535;
  text-decoration: underline;
}
.table .sort + .sort {
  margin-left: 1rem;
}

a.rating-link {
  color: rgba(0, 0, 0, 0.24);
}
a.rating-link:hover, a.rating-link.active {
  color: #f0ca4d;
}

.button-list > .btn {
  margin-bottom: 0.75rem;
  margin-left: 0.5rem;
}

.btn-rounded {
  border-radius: 100px;
}

.btn .material-icons {
  font-size: 18px;
  vertical-align: middle;
  position: relative;
  top: -1px;
}

.btn-light {
  color: #212529;
  background-color: white;
  border-color: #efefef;
}
.btn-light:hover {
  color: #212529;
  background-color: #ececec;
  border-color: #d6d6d6;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #ececec;
  border-color: #d6d6d6;
  box-shadow: 0 0 0 0.2rem rgba(208, 209, 209, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: white;
  border-color: #efefef;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #cfcfcf;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(208, 209, 209, 0.5);
}

.btn-flush {
  padding: 0;
  background: none;
  color: inherit;
  line-height: 1;
  box-shadow: none;
}

.btn-rounded-social {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 26px;
  text-align: center;
  vertical-align: middle;
  padding: 0;
}

.btn-group .btn-light.active {
  background: #1377c9 !important;
  color: #ffffff !important;
}

.btn-hover-primary:hover {
  color: #1377c9 !important;
}

.breadcrumb-item {
  text-transform: uppercase;
  letter-spacing: 0.85px;
  font-size: 0.625rem;
  font-weight: 600;
}
.breadcrumb-item i.material-icons {
  position: relative;
  top: -1px;
}
.breadcrumb-item a {
  color: #364C66;
}
.breadcrumb-item a:hover {
  color: #3687e4;
}

h1 + [aria-label=breadcrumb], h2 + [aria-label=breadcrumb], h3 + [aria-label=breadcrumb], h4 + [aria-label=breadcrumb], h5 + [aria-label=breadcrumb], h6 + [aria-label=breadcrumb],
.h1 + [aria-label=breadcrumb], .h2 + [aria-label=breadcrumb], .h3 + [aria-label=breadcrumb], .h4 + [aria-label=breadcrumb], .h5 + [aria-label=breadcrumb], .h6 + [aria-label=breadcrumb] {
  margin-top: -0.6rem;
}

.custom-control-label::before {
  box-shadow: none;
  background-color: white;
  border: 1px solid #EFEFEF;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border: none;
}

.card-form__body label:not([class]), .text-label-large, .text-label {
  font-size: 0.725rem;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  color: rgba(42, 42, 42, 0.84);
  line-height: 0.9375rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.text-label-large {
  font-size: 1rem;
  letter-spacing: 1px;
  font-weight: bold;
  line-height: 1.5rem;
}

.card-form .card-body {
  padding: 1.6875rem;
}
.card-form .card-body-form-group {
  padding: 1.25rem;
  padding-bottom: -0.625rem;
}
.card-form__body {
  background-color: #f6f6f6;
}
.form-control:focus {
  box-shadow: none !important;
}

.form-control-rounded {
  border-radius: 20rem;
}

.form-control-flush {
  padding: 0;
  border-width: 0;
  box-shadow: none;
  background-color: transparent;
}
.form-control-flush:focus {
  background-color: transparent;
  box-shadow: none;
}

.app-chat-container .list-group-flush {
  position: relative;
  z-index: 0;
}
.app-chat-container .custom-file-naked {
  width: 24px;
}

.custom-file-naked {
  cursor: pointer;
  width: auto;
  height: auto;
}
.custom-file-naked .custom-file-input {
  width: auto;
  height: auto;
  cursor: pointer;
  line-height: 1;
}
.custom-file-naked .custom-file-label {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  height: auto;
  cursor: pointer;
  line-height: 1;
  box-shadow: none;
  color: inherit;
}
.custom-file-naked .custom-file-label:after {
  display: none;
}

.input-group-text .fab {
  font-size: 18px;
}

.flatpickr-input[readonly] {
  background-color: #fff;
}

.flatpickr-wrapper {
  display: block;
}

.flatpickr-hidden-input {
  visibility: hidden;
  height: 0;
}

.flatpickr-calendar-right .flatpickr-calendar {
  right: -1.5rem;
}
@media (min-width: 576px) {
  .flatpickr-calendar-right .flatpickr-calendar {
    right: 0;
  }
}

.flatpickr-wrapper .flatpickr-input {
  height: 0;
  visibility: hidden;
  padding: 0;
}

.input-group-text .material-icons {
  font-size: inherit;
}

.input-group.input-group-merge .form-control {
  box-shadow: none;
}
.input-group.input-group-merge .form-control:focus ~ [class*=input-group] .input-group-text {
  border-color: #1377c9;
  border-width: 1px;
  color: #1377c9;
}
.input-group.input-group-merge .form-control.is-valid ~ [class*=input-group] .input-group-text, .was-validated .input-group.input-group-merge .form-control:valid ~ [class*=input-group] .input-group-text {
  border-color: #17b15c;
  color: #17b15c;
}
.input-group.input-group-merge .form-control.is-invalid ~ [class*=input-group] .input-group-text, .was-validated .input-group.input-group-merge .form-control:invalid ~ [class*=input-group] .input-group-text {
  border-color: #bb3434;
  color: #bb3434;
}
.input-group.input-group-merge .form-control-prepended {
  padding-left: 10px;
  border-left-width: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.input-group.input-group-merge .form-control-appended {
  padding-right: 0;
  border-right-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.input-group-merge .input-group-prepend {
  order: -1;
}
.input-group.input-group-merge .input-group-prepend > .input-group-text {
  border-right-width: 0 !important;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.input-group.input-group-merge .input-group-append > .input-group-text {
  border-left-width: 0 !important;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.input-group.input-group-merge.input-group-rounded .form-control-prepended {
  border-top-right-radius: 20rem;
  border-bottom-right-radius: 20rem;
}
.input-group.input-group-merge.input-group-rounded .form-control-appended {
  border-top-left-radius: 20rem;
  border-bottom-left-radius: 20rem;
}
.input-group.input-group-merge.input-group-rounded .input-group-prepend > .input-group-text {
  border-top-left-radius: 20rem;
  border-bottom-left-radius: 20rem;
}
.input-group.input-group-merge.input-group-rounded .input-group-append > .input-group-text {
  border-top-right-radius: 20rem;
  border-bottom-right-radius: 20rem;
}

.progress {
  box-shadow: none;
}

.page .nav-tabs {
  padding-left: 30px;
  background: #ffffff;
}
.page .nav-tabs a {
  font-size: 1rem;
  padding: 0.5rem 0.5rem;
  margin-right: 30px;
}

.card-header-tabs-basic, .nav-tabs {
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
}
.card-header-tabs-basic > div, .nav-tabs > div {
  display: flex;
}
.card-header-tabs-basic a, .nav-tabs a {
  padding: 0.5rem 0.75rem;
  color: rgba(53, 53, 53, 0.54);
  font-weight: 600;
}
.card-header-tabs-basic a:hover, .nav-tabs a:hover {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.84);
}
.card-header-tabs-basic .active, .nav-tabs .active {
  color: rgba(0, 0, 0, 0.84);
  border-bottom: 2px solid #1377c9;
}

.nav-tabs-custom {
  z-index: 2;
  position: relative;
}
.nav-tabs-custom .nav-item {
  margin-bottom: -1px;
  border-top-color: #1377c9;
}
.nav-tabs-custom .nav-item + .nav-item {
  margin-left: 0.5rem;
}
.nav-tabs-custom .nav-link {
  padding: 0.75rem 1.5rem;
  display: flex;
  flex-direction: column;
  color: rgba(0, 0, 0, 0.54);
  border-width: 1px;
  border-bottom-width: 0;
  border-color: transparent;
}
.nav-tabs-custom .nav-link__count {
  font-weight: 500;
  font-size: 1.1rem;
}
.nav-tabs-custom .nav-link.disabled {
  opacity: 0.75;
  background-color: rgba(0, 0, 0, 0.54) !important;
  color: #fff;
}
.nav-tabs-custom .nav-link.active, .nav-tabs-custom .nav-link:hover {
  color: rgba(0, 0, 0, 0.84);
}
.nav-tabs-custom .nav-link:hover {
  border-color: transparent;
}
.nav-tabs-custom .nav-link.active,
.nav-tabs-custom .nav-item.show .nav-link {
  border-color: #efefef;
}

.card-tab {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.list-group-small > .list-group-item {
  padding-top: 0.8125rem;
  padding-bottom: 0.8125rem;
}

.list-lessons {
  margin-bottom: 1.875rem;
}
.list-lessons .list-group-item a {
  font-size: 1rem;
  color: #353535;
  font-weight: 600;
}
.list-lessons .list-group-item:hover {
  background: #F9F9F9;
}
.list-lessons .list-group-item:hover a {
  text-decoration: none;
  color: #1377c9;
}
.list-lessons .list-group-item.active {
  background-color: #F9F9F9 !important;
  border: none;
  border-left: 2px solid #1377c9;
  background: none;
}
.list-lessons .list-group-item.active a {
  color: #1377c9;
}

.list-group.list-rankings .list-group-item {
  border-left: 0;
  border-right: 0;
}
.list-group.list-rankings .list-group-item:first-of-type {
  border-top: 0;
}
.list-group.list-rankings .list-group-item:last-of-type {
  border-bottom: 0;
}

#course_content {
  font-size: 1.1rem;
  line-height: 1.5;
}
#course_content > p {
  margin-bottom: 30px;
}
#course_content > h4 {
  font-weight: bold;
  font-size: 1.5rem;
}
#course_content > ul {
  margin: 30px 0;
  padding-left: 10px;
}
#course_content > ul li {
  display: flex !important;
  align-items: center !important;
  list-style: none;
  margin: 0 0 7.5px;
}
#course_content > ul li:before {
  font-family: "Material Icons";
  font-size: 1.6rem;
  position: relative;
  left: -5px;
  margin-right: 6px;
  color: #1377c9;
}
#course_content > ul li.chevron:before {
  content: "";
}
#course_content > ul li.confirm:before {
  content: "";
}

.border {
  border: 1px solid #efefef !important;
}

.border-top {
  border-top: 1px solid #efefef !important;
}

.border-right {
  border-right: 1px solid #efefef !important;
}

.border-bottom {
  border-bottom: 1px solid #efefef !important;
}

.border-left {
  border-left: 1px solid #efefef !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-left-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-right-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

@media (min-width: 576px) {
  .border-sm {
    border: 1px solid #efefef !important;
  }

  .border-top-sm {
    border-top: 1px solid #efefef !important;
  }

  .border-right-sm {
    border-right: 1px solid #efefef !important;
  }

  .border-bottom-sm {
    border-bottom: 1px solid #efefef !important;
  }

  .border-left-sm {
    border-left: 1px solid #efefef !important;
  }

  .border-sm-0 {
    border: 0 !important;
  }

  .border-top-sm-0 {
    border-top: 0 !important;
  }

  .border-right-sm-0 {
    border-right: 0 !important;
  }

  .border-bottom-sm-0 {
    border-bottom: 0 !important;
  }

  .border-left-sm-0 {
    border-left: 0 !important;
  }

  .rounded-sm {
    border-radius: 0.25rem !important;
  }

  .rounded-top-sm {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  .rounded-right-sm {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .rounded-bottom-sm {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .rounded-left-sm {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .rounded-sm-0 {
    border-radius: 0 !important;
  }

  .rounded-top-sm-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .rounded-left-sm-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .rounded-right-sm-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}
@media (min-width: 768px) {
  .border-md {
    border: 1px solid #efefef !important;
  }

  .border-top-md {
    border-top: 1px solid #efefef !important;
  }

  .border-right-md {
    border-right: 1px solid #efefef !important;
  }

  .border-bottom-md {
    border-bottom: 1px solid #efefef !important;
  }

  .border-left-md {
    border-left: 1px solid #efefef !important;
  }

  .border-md-0 {
    border: 0 !important;
  }

  .border-top-md-0 {
    border-top: 0 !important;
  }

  .border-right-md-0 {
    border-right: 0 !important;
  }

  .border-bottom-md-0 {
    border-bottom: 0 !important;
  }

  .border-left-md-0 {
    border-left: 0 !important;
  }

  .rounded-md {
    border-radius: 0.25rem !important;
  }

  .rounded-top-md {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  .rounded-right-md {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .rounded-bottom-md {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .rounded-left-md {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .rounded-md-0 {
    border-radius: 0 !important;
  }

  .rounded-top-md-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .rounded-left-md-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .rounded-right-md-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}
@media (min-width: 992px) {
  .border-lg {
    border: 1px solid #efefef !important;
  }

  .border-top-lg {
    border-top: 1px solid #efefef !important;
  }

  .border-right-lg {
    border-right: 1px solid #efefef !important;
  }

  .border-bottom-lg {
    border-bottom: 1px solid #efefef !important;
  }

  .border-left-lg {
    border-left: 1px solid #efefef !important;
  }

  .border-lg-0 {
    border: 0 !important;
  }

  .border-top-lg-0 {
    border-top: 0 !important;
  }

  .border-right-lg-0 {
    border-right: 0 !important;
  }

  .border-bottom-lg-0 {
    border-bottom: 0 !important;
  }

  .border-left-lg-0 {
    border-left: 0 !important;
  }

  .rounded-lg {
    border-radius: 0.25rem !important;
  }

  .rounded-top-lg {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  .rounded-right-lg {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .rounded-bottom-lg {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .rounded-left-lg {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .rounded-lg-0 {
    border-radius: 0 !important;
  }

  .rounded-top-lg-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .rounded-left-lg-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .rounded-right-lg-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}
@media (min-width: 1200px) {
  .border-xl {
    border: 1px solid #efefef !important;
  }

  .border-top-xl {
    border-top: 1px solid #efefef !important;
  }

  .border-right-xl {
    border-right: 1px solid #efefef !important;
  }

  .border-bottom-xl {
    border-bottom: 1px solid #efefef !important;
  }

  .border-left-xl {
    border-left: 1px solid #efefef !important;
  }

  .border-xl-0 {
    border: 0 !important;
  }

  .border-top-xl-0 {
    border-top: 0 !important;
  }

  .border-right-xl-0 {
    border-right: 0 !important;
  }

  .border-bottom-xl-0 {
    border-bottom: 0 !important;
  }

  .border-left-xl-0 {
    border-left: 0 !important;
  }

  .rounded-xl {
    border-radius: 0.25rem !important;
  }

  .rounded-top-xl {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  .rounded-right-xl {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .rounded-bottom-xl {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .rounded-left-xl {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .rounded-xl-0 {
    border-radius: 0 !important;
  }

  .rounded-top-xl-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .rounded-left-xl-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .rounded-right-xl-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}
.custom-checkbox-toggle {
  width: 3rem;
  height: 1.5rem;
  padding-left: 0;
}
.custom-checkbox-toggle .custom-control-label {
  position: relative;
  width: 100%;
  height: 100%;
}
.custom-checkbox-toggle .custom-control-label::after, .custom-checkbox-toggle .custom-control-label::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  transition: all 0.2s ease;
  border-radius: 1.5rem;
}
.custom-checkbox-toggle .custom-control-label:before {
  width: 100%;
  height: 100%;
  background-color: #e3ebf6;
}
.custom-checkbox-toggle .custom-control-label::after {
  width: 1.5rem;
  height: 100%;
  transform: scale(0.8);
  background-color: #fff;
}
.custom-checkbox-toggle .custom-control-input:checked ~ .custom-control-label::after {
  right: 0;
  left: 1.5rem;
  background-color: #fff;
}

.alert-soft-primary {
  color: #1377c9;
  background-color: rgba(19, 119, 201, 0.05);
  border-color: #bdd9f0;
}
.alert-soft-primary hr {
  border-top-color: #a8cdeb;
}
.alert-soft-primary .alert-link {
  color: #0f5b9a;
}

.alert-soft-secondary {
  color: #262f36;
  background-color: rgba(38, 47, 54, 0.05);
  border-color: #c2c5c7;
}
.alert-soft-secondary hr {
  border-top-color: #b5b8bb;
}
.alert-soft-secondary .alert-link {
  color: #111518;
}

.alert-soft-success {
  color: #17b15c;
  background-color: rgba(23, 177, 92, 0.05);
  border-color: #bee9d1;
}
.alert-soft-success hr {
  border-top-color: #abe3c4;
}
.alert-soft-success .alert-link {
  color: #118445;
}

.alert-soft-info {
  color: #13C0C9;
  background-color: rgba(19, 192, 201, 0.05);
  border-color: #bdedf0;
}
.alert-soft-info hr {
  border-top-color: #a8e7eb;
}
.alert-soft-info .alert-link {
  color: #0f939a;
}

.alert-soft-warning {
  color: #f0ca4d;
  background-color: rgba(240, 202, 77, 0.05);
  border-color: #fbf0cd;
}
.alert-soft-warning hr {
  border-top-color: #f9e9b5;
}
.alert-soft-warning .alert-link {
  color: #ecbc1e;
}

.alert-soft-danger {
  color: #bb3434;
  background-color: rgba(187, 52, 52, 0.05);
  border-color: #ecc6c6;
}
.alert-soft-danger hr {
  border-top-color: #e6b3b3;
}
.alert-soft-danger .alert-link {
  color: #932929;
}

.alert-soft-light {
  color: #f8f9fa;
  background-color: rgba(248, 249, 250, 0.05);
  border-color: #fdfdfe;
}
.alert-soft-light hr {
  border-top-color: #ececf6;
}
.alert-soft-light .alert-link {
  color: #dae0e5;
}

.alert-soft-dark {
  color: #343a40;
  background-color: rgba(52, 58, 64, 0.05);
  border-color: #c6c8ca;
}
.alert-soft-dark hr {
  border-top-color: #b9bbbe;
}
.alert-soft-dark .alert-link {
  color: #1d2124;
}

.alert-soft-blue {
  color: #3687e4;
  background-color: rgba(54, 135, 228, 0.05);
  border-color: #c7ddf7;
}
.alert-soft-blue hr {
  border-top-color: #b1cff4;
}
.alert-soft-blue .alert-link {
  color: #1b6dcc;
}

.alert-soft-teal {
  color: #1ea190;
  background-color: rgba(30, 161, 144, 0.05);
  border-color: #c0e5e0;
}
.alert-soft-teal hr {
  border-top-color: #aeded7;
}
.alert-soft-teal .alert-link {
  color: #16766a;
}

.alert-soft-purple {
  color: #5163C5;
  background-color: rgba(81, 99, 197, 0.05);
  border-color: #ced3ef;
}
.alert-soft-purple hr {
  border-top-color: #bbc2e9;
}
.alert-soft-purple .alert-link {
  color: #394aaa;
}

.alert-soft-dark-gray {
  color: #2A2A2A;
  background-color: rgba(42, 42, 42, 0.05);
  border-color: #c3c3c3;
}
.alert-soft-dark-gray hr {
  border-top-color: #b6b6b6;
}
.alert-soft-dark-gray .alert-link {
  color: #111111;
}

.alert-soft-light-gray {
  color: rgba(0, 0, 0, 0.24);
  background-color: rgba(0, 0, 0, 0.05);
  border-color: rgba(242, 242, 242, 0.7872);
}
.alert-soft-light-gray hr {
  border-top-color: rgba(229, 229, 229, 0.7872);
}
.alert-soft-light-gray .alert-link {
  color: rgba(0, 0, 0, 0.24);
}

.alert-soft-muted-light {
  color: #DBDFE4;
  background-color: rgba(219, 223, 228, 0.05);
  border-color: #f5f6f7;
}
.alert-soft-muted-light hr {
  border-top-color: #e7e9ec;
}
.alert-soft-muted-light .alert-link {
  color: #bec5ce;
}

.alert-soft-facebook {
  color: #3b5998;
  background-color: rgba(59, 89, 152, 0.05);
  border-color: #c8d1e2;
}
.alert-soft-facebook hr {
  border-top-color: #b7c3d9;
}
.alert-soft-facebook .alert-link {
  color: #2d4373;
}

.alert-soft-twitter {
  color: #00aced;
  background-color: rgba(0, 172, 237, 0.05);
  border-color: #b8e8fa;
}
.alert-soft-twitter hr {
  border-top-color: #a0e0f8;
}
.alert-soft-twitter .alert-link {
  color: #0087ba;
}

.alert-soft-vuejs {
  color: #42b883;
  background-color: rgba(66, 184, 131, 0.05);
  border-color: #caebdc;
}
.alert-soft-vuejs hr {
  border-top-color: #b7e4d0;
}
.alert-soft-vuejs .alert-link {
  color: #359268;
}

.alert-soft-angular {
  color: #D32F2F;
  background-color: rgba(211, 47, 47, 0.05);
  border-color: #f3c5c5;
}
.alert-soft-angular hr {
  border-top-color: #efb0b0;
}
.alert-soft-angular .alert-link {
  color: #ab2424;
}

.alert-soft-gulp {
  color: #cf4647;
  background-color: rgba(207, 70, 71, 0.05);
  border-color: #f2cbcb;
}
.alert-soft-gulp hr {
  border-top-color: #edb7b7;
}
.alert-soft-gulp .alert-link {
  color: #b32f30;
}

.alert-soft-node {
  color: #699f63;
  background-color: rgba(105, 159, 99, 0.05);
  border-color: #d5e4d3;
}
.alert-soft-node hr {
  border-top-color: #c6dac3;
}
.alert-soft-node .alert-link {
  color: #54804f;
}

.alert-soft-gitlab {
  color: #e65328;
  background-color: rgba(230, 83, 40, 0.05);
  border-color: #f8cfc3;
}
.alert-soft-gitlab hr {
  border-top-color: #f5bdac;
}
.alert-soft-gitlab .alert-link {
  color: #c43e17;
}

.alert-soft-instagram {
  color: #3f729b;
  background-color: rgba(63, 114, 155, 0.05);
  border-color: #c9d8e3;
}
.alert-soft-instagram hr {
  border-top-color: #b8ccda;
}
.alert-soft-instagram .alert-link {
  color: #305777;
}

.alert-light,
.alert-soft-light {
  color: #818182;
  background-color: #f8f9fa;
  border-color: #e9ecef;
}
.alert-light hr,
.alert-soft-light hr {
  border-top-color: #e9ecef;
}

.is-loading {
  position: relative;
  color: transparent !important;
}

.is-loading:after,
.loader {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  animation: 1s is-loading linear infinite;
  border: 3px solid #353535;
  border-bottom-color: transparent;
  border-radius: 50%;
  background: transparent;
}

.is-loading-sm:after, .loader-sm {
  width: 1rem;
  height: 1rem;
  border-width: 2px;
}

.is-loading-lg:after, .loader-lg {
  width: 2rem;
  height: 2rem;
  border-width: 5px;
}

.is-loading > * {
  opacity: 0 !important;
}

.is-loading:after {
  position: absolute;
  top: calc(50% - 1.5rem / 2);
  left: calc(50% - 1.5rem / 2);
  content: "";
}

.is-loading-sm:after {
  top: calc(50% - 1rem / 2);
  left: calc(50% - 1rem / 2);
}

.is-loading-lg:after {
  top: calc(50% - 2rem / 2);
  left: calc(50% - 2rem / 2);
}

.is-loading-primary:after,
.btn-outline-primary.is-loading:after,
.loader-primary {
  border-color: #1377c9;
  border-bottom-color: transparent;
}

.btn-primary.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-secondary:after,
.btn-outline-secondary.is-loading:after,
.loader-secondary {
  border-color: #262f36;
  border-bottom-color: transparent;
}

.btn-secondary.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-success:after,
.btn-outline-success.is-loading:after,
.loader-success {
  border-color: #17b15c;
  border-bottom-color: transparent;
}

.btn-success.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-info:after,
.btn-outline-info.is-loading:after,
.loader-info {
  border-color: #13C0C9;
  border-bottom-color: transparent;
}

.btn-info.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-warning:after,
.btn-outline-warning.is-loading:after,
.loader-warning {
  border-color: #f0ca4d;
  border-bottom-color: transparent;
}

.btn-warning.is-loading:after {
  border-color: #212529;
  border-bottom-color: transparent;
}

.is-loading-danger:after,
.btn-outline-danger.is-loading:after,
.loader-danger {
  border-color: #bb3434;
  border-bottom-color: transparent;
}

.btn-danger.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-light:after,
.btn-outline-light.is-loading:after,
.loader-light {
  border-color: #f8f9fa;
  border-bottom-color: transparent;
}

.btn-light.is-loading:after {
  border-color: #212529;
  border-bottom-color: transparent;
}

.is-loading-dark:after,
.btn-outline-dark.is-loading:after,
.loader-dark {
  border-color: #343a40;
  border-bottom-color: transparent;
}

.btn-dark.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-blue:after,
.btn-outline-blue.is-loading:after,
.loader-blue {
  border-color: #3687e4;
  border-bottom-color: transparent;
}

.btn-blue.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-teal:after,
.btn-outline-teal.is-loading:after,
.loader-teal {
  border-color: #1ea190;
  border-bottom-color: transparent;
}

.btn-teal.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-purple:after,
.btn-outline-purple.is-loading:after,
.loader-purple {
  border-color: #5163C5;
  border-bottom-color: transparent;
}

.btn-purple.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-dark-gray:after,
.btn-outline-dark-gray.is-loading:after,
.loader-dark-gray {
  border-color: #2A2A2A;
  border-bottom-color: transparent;
}

.btn-dark-gray.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-light-gray:after,
.btn-outline-light-gray.is-loading:after,
.loader-light-gray {
  border-color: rgba(0, 0, 0, 0.24);
  border-bottom-color: transparent;
}

.btn-light-gray.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-muted-light:after,
.btn-outline-muted-light.is-loading:after,
.loader-muted-light {
  border-color: #DBDFE4;
  border-bottom-color: transparent;
}

.btn-muted-light.is-loading:after {
  border-color: #212529;
  border-bottom-color: transparent;
}

.is-loading-facebook:after,
.btn-outline-facebook.is-loading:after,
.loader-facebook {
  border-color: #3b5998;
  border-bottom-color: transparent;
}

.btn-facebook.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-twitter:after,
.btn-outline-twitter.is-loading:after,
.loader-twitter {
  border-color: #00aced;
  border-bottom-color: transparent;
}

.btn-twitter.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-vuejs:after,
.btn-outline-vuejs.is-loading:after,
.loader-vuejs {
  border-color: #42b883;
  border-bottom-color: transparent;
}

.btn-vuejs.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-angular:after,
.btn-outline-angular.is-loading:after,
.loader-angular {
  border-color: #D32F2F;
  border-bottom-color: transparent;
}

.btn-angular.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-gulp:after,
.btn-outline-gulp.is-loading:after,
.loader-gulp {
  border-color: #cf4647;
  border-bottom-color: transparent;
}

.btn-gulp.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-node:after,
.btn-outline-node.is-loading:after,
.loader-node {
  border-color: #699f63;
  border-bottom-color: transparent;
}

.btn-node.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-gitlab:after,
.btn-outline-gitlab.is-loading:after,
.loader-gitlab {
  border-color: #e65328;
  border-bottom-color: transparent;
}

.btn-gitlab.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-instagram:after,
.btn-outline-instagram.is-loading:after,
.loader-instagram {
  border-color: #3f729b;
  border-bottom-color: transparent;
}

.btn-instagram.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

@keyframes is-loading {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
.loader-list {
  display: flex;
  align-items: center;
}
.loader-list > .loader {
  margin-bottom: 0.75rem;
  margin-left: 0.5rem;
}

.border-style-dashed {
  border-style: dashed !important;
}

.border-bottom-2 {
  border-bottom: 2px solid #efefef;
}

.text-body {
  color: #353535;
}
.text-body[href]:hover {
  color: #353535;
}

.text-underline {
  text-decoration: underline;
}

.text-underline-0 {
  text-decoration: none;
}
.text-underline-0:hover {
  text-decoration: none;
}

.z-0 {
  z-index: 0;
  position: relative;
}

.lh-12 {
  line-height: 1.2;
}

.badge {
  box-sizing: border-box;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
}

.badge-light-gray {
  color: rgba(102, 118, 138, 0.4);
}

.badge-notifications {
  font-size: 0.75rem;
  padding: 0.25rem 0.4rem;
}

.badge-soft-primary {
  color: #1169b2;
  background-color: rgba(19, 119, 201, 0.1);
}

.badge-soft-secondary {
  color: #1c2227;
  background-color: rgba(38, 47, 54, 0.1);
}

.badge-soft-success {
  color: #149a50;
  background-color: rgba(23, 177, 92, 0.1);
}

.badge-soft-info {
  color: #11aab2;
  background-color: rgba(19, 192, 201, 0.1);
}

.badge-soft-warning {
  color: #eec335;
  background-color: rgba(240, 202, 77, 0.1);
}

.badge-soft-danger {
  color: #a72e2e;
  background-color: rgba(187, 52, 52, 0.1);
}

.badge-soft-light {
  color: #e9ecef;
  background-color: rgba(248, 249, 250, 0.1);
}

.badge-soft-dark {
  color: #292d32;
  background-color: rgba(52, 58, 64, 0.1);
}

.badge-soft-blue {
  color: #207ae1;
  background-color: rgba(54, 135, 228, 0.1);
}

.badge-soft-teal {
  color: #1a8c7d;
  background-color: rgba(30, 161, 144, 0.1);
}

.badge-soft-purple {
  color: #3f53bd;
  background-color: rgba(81, 99, 197, 0.1);
}

.badge-soft-dark-gray {
  color: #1d1d1d;
  background-color: rgba(42, 42, 42, 0.1);
}

.badge-soft-light-gray {
  color: rgba(0, 0, 0, 0.24);
  background-color: rgba(0, 0, 0, 0.1);
}

.badge-soft-muted-light {
  color: #ccd2d9;
  background-color: rgba(219, 223, 228, 0.1);
}

.badge-soft-facebook {
  color: #344e86;
  background-color: rgba(59, 89, 152, 0.1);
}

.badge-soft-twitter {
  color: #0099d4;
  background-color: rgba(0, 172, 237, 0.1);
}

.badge-soft-vuejs {
  color: #3ba576;
  background-color: rgba(66, 184, 131, 0.1);
}

.badge-soft-angular {
  color: #c02929;
  background-color: rgba(211, 47, 47, 0.1);
}

.badge-soft-gulp {
  color: #c83435;
  background-color: rgba(207, 70, 71, 0.1);
}

.badge-soft-node {
  color: #5e9059;
  background-color: rgba(105, 159, 99, 0.1);
}

.badge-soft-gitlab {
  color: #db4519;
  background-color: rgba(230, 83, 40, 0.1);
}

.badge-soft-instagram {
  color: #386589;
  background-color: rgba(63, 114, 155, 0.1);
}

.badge-lg {
  font-size: 1.8rem;
  padding: 0.5rem 1rem;
}

.avatar {
  flex-shrink: 0;
  font-size: 1rem;
  display: inline-block;
  width: 3rem;
  height: 3rem;
  position: relative;
  z-index: 0;
}
.avatar-course-img {
  width: 100%;
}
.avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.avatar-online::before, .avatar-online::after, .avatar-offline::before, .avatar-offline::after {
  position: absolute;
  right: 5%;
  bottom: 5%;
  width: 20%;
  height: 20%;
  content: "";
  border-radius: 50%;
}
.avatar-online::before, .avatar-offline::before {
  width: 22%;
  height: 22%;
  right: 4%;
  bottom: 4%;
  background-color: white;
}
.avatar-online::after {
  background-color: #17b15c;
}
.avatar-offline::after {
  background-color: #2A2A2A;
}
.avatar-title {
  display: flex;
  width: 100%;
  height: 100%;
  color: #fff;
  align-items: center;
  justify-content: center;
}
.avatar-xl, .avatar-xxl {
  font-size: 1.70833rem;
  width: 5.125rem;
  height: 5.125rem;
}
.avatar-lg {
  font-size: 1.33333rem;
  width: 4rem;
  height: 4rem;
}
.avatar-sm {
  width: 2.5rem;
  height: 2.5rem;
  font-weight: bold;
}
.avatar-xs {
  font-size: 0.54167rem;
  width: 35px;
  height: 35px;
  font-weight: bold;
}
.avatar-xxs {
  font-size: 0.54167rem;
  width: 20px;
  height: 20px;
  font-weight: bold;
}
.avatar-xxs.avatar-online::before, .avatar-xxs.avatar-online::after, .avatar-xxs.avatar-offline::before, .avatar-xxs.avatar-offline::after {
  position: absolute;
  right: -1px;
  bottom: -1px;
  width: 25%;
  height: 25%;
  content: "";
  border-radius: 50%;
}
@media (min-width: 768px) {
  .avatar-xxl {
    font-size: 2.66667rem;
    width: 8rem;
    height: 8rem;
  }
}
.avatar.avatar-4by3 {
  width: 4rem;
}
.avatar-xxl.avatar-4by3 {
  width: 10.66667rem;
}
.avatar-xl.avatar-4by3 {
  width: 6.83333rem;
}
.avatar-lg.avatar-4by3 {
  width: 5.33333rem;
}
.avatar-group {
  display: inline-flex;
}
.avatar-group .avatar:hover {
  z-index: 1;
}
.avatar-group .avatar-img,
.avatar-group .avatar-title {
  border: 2px solid white;
}
.avatar-group .avatar + .avatar {
  margin-left: -0.75rem;
}
.avatar-group .avatar-lg + .avatar-lg {
  margin-left: -1rem;
}
.avatar-group .avatar-xl + .avatar-xl {
  margin-left: -1.28125rem;
}
.avatar-group .avatar-xxl + .avatar-xxl {
  margin-left: -2rem;
}
.avatar-group .avatar-sm + .avatar-sm {
  margin-left: -0.625rem;
}
.avatar-group .avatar-xs + .avatar-xs {
  margin-left: -0.40625rem;
}

.avatar-list > .avatar,
.avatar-list > .avatar-group {
  margin-bottom: 0.75rem;
  margin-left: 0.5rem;
}

.chart {
  position: relative;
  height: 300px;
}

.chart-legend {
  display: flex;
  margin-top: 2.5rem;
  white-space: nowrap;
  justify-content: center;
}
.chart-legend-item {
  display: flex;
  align-items: center;
  justify-content: start;
}
.chart-legend:not(.chart-legend--vertical) .chart-legend-item + .chart-legend-item {
  margin-left: 1rem;
}
.chart-legend--vertical {
  flex-direction: column;
}

.chart-legend-indicator {
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  margin-right: 1rem;
  border-radius: 50%;
}

#chart-tooltip {
  z-index: 0;
  pointer-events: none;
}

#chart-tooltip .arrow {
  top: 100%;
  left: 50%;
  transform: translateX(-50%) translateX(-0.5rem);
}

.popover-header {
  border-bottom: 0;
}

.popover-body-label, .popover-body-value {
  margin-left: 0.25rem;
}

.popover-body-indicator {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
}

.card-chart-stats {
  position: relative;
  padding-bottom: calc(80px - 1.25rem);
  overflow: hidden;
  z-index: 0;
}
.card-chart-stats .chart {
  height: 80px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.dashboard-chart {
  width: calc(250px - 1.25rem * 2);
  height: calc(250px - 1.25rem * 2);
}
.dashboard-chart .d-flex {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.page-item + .page-item {
  margin-left: 0.05rem;
}

.page-link {
  min-width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
  padding-top: 0;
  padding-bottom: 0;
  font-weight: 600;
}
.page-link:hover {
  background: #1377c9;
  color: #fff;
}

.pagination-rounded .page-link {
  border-radius: 100px;
}
.pagination-rounded .page-item + .page-item {
  margin-left: 0.625rem;
}
.pagination-rounded .page-item:first-child .page-link, .pagination-rounded .page-item:last-child .page-link {
  border-radius: 100px;
}

.pagination-light .page-item:not(.active) .page-link {
  background: transparent;
}
.pagination-light .page-item:not(.active) .page-link:hover {
  color: #1377c9;
}
.pagination-light .page-item .page-link {
  border-radius: 100px;
}
.pagination-light .page-item:first-child .page-link, .pagination-light .page-item:last-child .page-link {
  border-radius: 100px;
}

.page-separator {
  position: relative;
  color: rgba(0, 0, 0, 0.54);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
  margin: 0.5rem 0;
}
.page-separator::before {
  content: "";
  height: 1px;
  background-color: #efefef;
  width: 100%;
  top: 50%;
  left: 0;
  position: absolute;
  z-index: -1;
}
.page-separator__text {
  display: inline-flex;
  padding: 0.25rem 1rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  color: #2A2A2A;
  line-height: 0.9375rem;
  font-weight: bold;
  background-color: white;
  max-width: 90%;
}
.page-separator__text:hover {
  text-decoration: none;
}

/*-----------------------------------------------------------------*/
/*------------------------------------------------------------------
3. Apps
-------------------------------------------------------------------*/
.trello {
  overflow: hidden;
}
.trello .mdk-drawer-layout,
.trello .mdk-header-layout,
.trello .mdk-header-layout__content {
  height: 100%;
}
.trello .page {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 0;
}
.trello-container {
  flex: 1 1 0%;
  position: relative;
  height: 100%;
  overflow: hidden;
}
.trello-board {
  display: block;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  height: 100%;
}
.trello-board__tasks {
  display: inline-flex;
  align-items: start;
  width: 22rem;
  height: 100%;
}
.trello-board__tasks:not(:last-child) {
  margin-right: 1.25rem;
}
.trello-board__tasks .card {
  width: 100%;
  max-height: calc(100% - 1.5625rem);
}
.trello-board__tasks .card .card-header {
  border-bottom: 2px solid #efefef;
}
.trello-board__tasks .card-body {
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.trello-board__tasks-list {
  min-height: 10px;
}
.trello-board__tasks-item {
  margin-bottom: 0.625rem;
  border-color: rgba(53, 53, 53, 0.1) !important;
}
.trello-board__tasks-item:hover {
  background: rgba(53, 53, 53, 0.03);
}

.app-chat {
  overflow: hidden;
}
.app-chat .mdk-drawer-layout,
.app-chat .mdk-header-layout,
.app-chat .mdk-header-layout__content {
  height: 100%;
}
.app-chat .page {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 0;
  margin-top: 0;
}
.app-chat-container {
  flex: 1 1 0%;
  position: relative;
  height: 100%;
  overflow: hidden;
  z-index: 0;
}

.app-email {
  overflow: hidden;
}
.app-email .mdk-drawer-layout,
.app-email .mdk-header-layout,
.app-email .mdk-header-layout__content {
  height: 100%;
}
.app-email .page {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 0;
  margin-top: 0;
}
.app-email-container {
  flex: 1 1 0%;
  position: relative;
  height: 100%;
  overflow: hidden;
  z-index: 0;
}
.app-email-sidebar {
  max-width: 250px;
}
.app-email .simplebar-placeholder {
  width: auto !important;
}

.app-tickets {
  overflow: hidden;
}
.app-tickets .mdk-drawer-layout,
.app-tickets .mdk-header-layout,
.app-tickets .mdk-header-layout__content {
  height: 100%;
}
.app-tickets .page {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 0;
  margin-top: 0;
}
.app-tickets-container {
  flex: 1 1 0%;
  position: relative;
  height: 100%;
  overflow: hidden;
  z-index: 0;
}

/*-----------------------------------------------------------------*/
/*------------------------------------------------------------------
4. Plugin Overrides
-------------------------------------------------------------------*/
.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #1377c9;
}

.daterangepicker .ranges li.active {
  background-color: #1377c9;
}

#toast-container > div {
  opacity: 1;
  box-shadow: 0 10px 25px 0 rgba(50, 50, 93, 0.07), 0 5px 15px 0 rgba(0, 0, 0, 0.07);
}
#toast-container > div:hover {
  box-shadow: 0 10px 25px 0 rgba(50, 50, 93, 0.12), 0 5px 15px 0 rgba(0, 0, 0, 0.12);
}

.toast-primary {
  background-color: #1377c9;
}

.toast-secondary {
  background-color: #262f36;
}

.toast-success {
  background-color: #17b15c;
}

.toast-info {
  background-color: #13C0C9;
}

.toast-warning {
  background-color: #f0ca4d;
}

.toast-danger {
  background-color: #bb3434;
}

.toast-light {
  background-color: #f8f9fa;
}

.toast-dark {
  background-color: #343a40;
}

.toast-blue {
  background-color: #3687e4;
}

.toast-teal {
  background-color: #1ea190;
}

.toast-purple {
  background-color: #5163C5;
}

.toast-dark-gray {
  background-color: #2A2A2A;
}

.toast-light-gray {
  background-color: rgba(0, 0, 0, 0.24);
}

.toast-muted-light {
  background-color: #DBDFE4;
}

.toast-facebook {
  background-color: #3b5998;
}

.toast-twitter {
  background-color: #00aced;
}

.toast-vuejs {
  background-color: #42b883;
}

.toast-angular {
  background-color: #D32F2F;
}

.toast-gulp {
  background-color: #cf4647;
}

.toast-node {
  background-color: #699f63;
}

.toast-gitlab {
  background-color: #e65328;
}

.toast-instagram {
  background-color: #3f729b;
}

.fc button {
  height: auto;
}

.fc td {
  padding: 0 !important;
}

.fc-event {
  line-height: 1.5;
  border-color: transparent;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
}

.fc-title {
  font-weight: 600;
}

.fc-popover .fc-header {
  position: relative;
  padding: 0.25rem 0.5rem;
}
.fc-popover .fc-header .fc-close {
  position: absolute;
  top: 0.6rem;
  right: 0.5rem;
}

.external-event {
  padding: 0.25rem 0.5rem;
  color: white;
  margin-bottom: 0.5rem;
  display: flex;
  border-radius: 0.25rem;
  cursor: move;
}

.preloader {
  height: 100%;
  width: 100%;
  top: 0px;
  position: fixed;
  z-index: 99999;
  background: #343a40;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

/*-----------------------------------------------------------------*/
/*------------------------------------------------------------------
5. Pages / Components
-------------------------------------------------------------------*/
.dashboard-area-tabs__tab {
  display: flex;
  flex-direction: column;
}
.dashboard-area-tabs__tab:hover {
  text-decoration: none;
}
.dashboard-area-tabs__tab .text-label-large {
  color: #DBDFE4;
}
.dashboard-area-tabs__tab:not(.active) {
  background-color: #f8f9fa;
}
.dashboard-area-tabs__tab:not(.active) .text-amount {
  color: #2A2A2A;
}
.dashboard-area-tabs__tab.active .text-label-large {
  color: #1377c9;
}

.dashboard-location-tabs__tab {
  margin-bottom: 10px;
  cursor: pointer;
}
.dashboard-location-tabs__tab:not(.active) .progress-bar {
  background: rgba(0, 0, 0, 0.54) !important;
}

.list-todo:last-child {
  margin-bottom: 0;
}
.list-todo li {
  padding-left: 1rem;
}
.list-todo li:not(:last-child) {
  margin-bottom: 1rem;
}
.list-todo .custom-control-input:checked ~ .custom-control-label {
  text-decoration: line-through;
}
.list-todo .custom-control {
  padding-left: 2rem;
}
.list-todo .custom-control-label::before, .list-todo .custom-control-label::after {
  left: -2rem;
}

.list-skills li {
  display: flex;
  align-items: center;
}
.list-skills li:not(:last-child) {
  margin-bottom: 1rem;
}
.list-skills li > div + div {
  padding-left: 1rem;
}
.list-skills li > div:first-child {
  width: 110px;
}
.list-skills:last-child {
  margin-bottom: 0;
}

.layout-login {
  background-size: cover;
  background-image: url("../images/login/alexandre-godreau-431553-unsplash.jpg");
  background-repeat: no-repeat;
  position: relative;
}
.layout-login__overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(52, 58, 64, 0.5);
  z-index: -1;
  pointer-events: none;
}
.layout-login__form {
  padding: 2rem;
  position: relative;
}
@media (min-width: 768px) {
  .layout-login {
    display: flex;
  }
  .layout-login__form {
    min-width: calc(320px + 3rem * 2);
    padding: 3rem;
  }
}

.layout-login-centered-boxed {
  height: initial;
}
.layout-login-centered-boxed__form {
  padding: 2rem;
  position: relative;
}
@media (min-width: 768px) {
  .layout-login-centered-boxed {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .layout-login-centered-boxed__form {
    max-width: 28rem;
    min-width: calc(320px + 3rem * 2);
    padding: 3rem;
  }
}

.stories-card {
  margin-bottom: 15px;
}
.stories-card:last-child {
  margin-bottom: 0;
}
.stories-card__content {
  padding: 0.5rem;
}
.stories-card__title {
  white-space: nowrap;
}
.stories-card__title,
.stories-card .card-title,
.stories-card .card-title > a {
  max-width: 180px;
}
.stories-card .card-title > a {
  display: inline-block;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 1199.98px) {
  .stories-card__meta {
    margin-top: 0.4375rem;
    padding-top: 0.4375rem;
    border-top: 1px solid #efefef;
    width: 100%;
    order: 3;
    align-items: flex-start;
  }
}
@media (min-width: 576px) {
  .stories-card__tag {
    width: 100px;
  }
  .stories-card__date {
    width: 140px;
  }
  .stories-card__title,
.stories-card .card-title,
.stories-card .card-title > a {
    max-width: 300px;
  }
}
.stories-cards {
  margin-bottom: 15px;
}
.stories-card-popular {
  position: relative;
  overflow: hidden;
}
.stories-card-popular__content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(52, 58, 64, 0.125);
}
.stories-card-popular__content a {
  text-decoration: none;
}
.stories-card-popular .card-img {
  height: 256px;
  object-fit: cover;
}
.stories-card-popular a {
  color: white;
}
.stories-card-popular__title {
  position: absolute;
  left: 0;
  bottom: 0;
}
.stories-card-popular__title .text-muted {
  color: rgba(255, 255, 255, 0.54) !important;
}

.map {
  width: 100%;
  min-height: 300px;
  position: relative;
  overflow: hidden;
}

.map-pin {
  width: 64px;
  height: 50px;
  position: absolute;
  top: -25px;
  left: -32px;
  background-size: 32px 32px;
  background-repeat: no-repeat;
  text-align: center;
  background-position: top center;
  color: #888;
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
}

.map-pin span {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  left: 0;
}

.blue {
  background-image: url("../images/marker/blue.png");
}

.jqvmap-zoomin,
.jqvmap-zoomout {
  box-sizing: content-box;
  background: #1377c9;
  padding: 0.25rem;
}

.jqvmap-label {
  z-index: 1;
  border: 1px solid #efefef;
  border-radius: 0.25rem;
  background: white;
  padding: 0.25rem 0.5rem;
  color: #353535;
}

.pricing__title {
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: normal;
}
.pricing__amount {
  font-size: 3.4rem;
  line-height: 4.2rem;
  font-weight: 600;
  margin-right: 0.466rem;
}
.pricing__currency {
  font-size: 1.4rem;
  line-height: 1.5rem;
  font-weight: 600;
}
.pricing__duration {
  font-size: 0.933rem;
  letter-spacing: 1.4px;
  line-height: 1.066rem;
}
.pricing__card--popular {
  background-color: #F6FAFD;
  position: relative;
}
.pricing__card--popular .pricing__card-badge {
  background-color: #EE4059;
  color: white;
  padding: 0.2rem 0.66rem;
  border-radius: 0.2rem 0.2rem 0 0;
  font-weight: 700;
  font-size: 0.66rem;
  font-style: italic;
  letter-spacing: 1px;
  line-height: 0.8rem;
  position: absolute;
  top: -1.2rem;
  right: 1.2rem;
  display: inline-block;
  text-transform: uppercase;
}
.pricing__features {
  letter-spacing: 1.4px;
  line-height: 1.86rem;
}
.pricing__features li {
  padding-left: 1.466rem;
  position: relative;
}
.pricing__features li::after {
  content: "";
  width: 0.5rem;
  height: 0.5rem;
  background-color: #1377c9;
  border-radius: 100%;
  left: 0;
  top: 0.6rem;
  position: absolute;
}

.dp-preview {
  position: relative;
  z-index: 0;
  text-decoration: none;
  display: block;
  margin-bottom: 1.5rem;
  border-radius: 0.3rem;
  overflow: hidden;
}
.dp-preview__overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  content: "";
  background-color: rgba(19, 119, 201, 0.9);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.25s ease;
  z-index: 1;
}
.dp-preview:hover .dp-preview__overlay {
  opacity: 1;
}

/*-----------------------------------------------------------------*/