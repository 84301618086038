.stories {
  &-card {
    margin-bottom: $grid-gutter-width/2;

    &:last-child {
      margin-bottom: 0;
    }
    &__content {
      padding: $spacer/2;
    }
    &__title {
      white-space: nowrap;
    }
    &__title,
    .card-title,
    .card-title > a {
      max-width: 180px; 
    }
    
    .card-title > a {
      display: inline-block; 
      overflow-x: hidden; 
      text-overflow: ellipsis;
    }
    @include media-breakpoint-down(lg) {
      &__meta {
        margin-top: .4375rem;
        padding-top: .4375rem;
        border-top: 1px solid $border-color;
        width: 100%;
        order: 3;
        align-items: flex-start;
      }
    }
    @include media-breakpoint-up(sm) {
      &__tag {
        width: 100px;
      }
      &__date {
        width: 140px;
      }
      &__title,
      .card-title,
      .card-title > a {
        max-width: 300px;
      }
    }
  }
  &-cards {
    margin-bottom: $card-group-margin;
  }

  &-card-popular {
    position: relative;
    overflow: hidden;
    &__content {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(theme-color('dark'), .125);
      a {
        text-decoration: none;
      }
    }
    .card-img {
      height: 256px;
      object-fit: cover;
    }
    a {
      color: white;
    }
    &__title {
      position: absolute;
      left: 0;
      bottom: 0;
      .text-muted {
        color: rgba(255,255,255, .54) !important;
      }  
    }
  }
}