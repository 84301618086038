.chart {
  position: relative;
  height: 300px;
}

.chart-legend {
  display: flex;
  margin-top: 2.5rem;
  white-space: nowrap;
  justify-content: center;

  &-item {
    display: flex;
    align-items: center;
    justify-content: start;
  }

  &:not(&--vertical) {
    .chart-legend-item + .chart-legend-item {
      margin-left: 1rem;
    }
  }

  &--vertical {
    flex-direction: column;
  }
}

.chart-legend-indicator {
  display: block;
  width: .5rem;
  height: .5rem;
  margin-right: 1rem;
  border-radius: 50%;
}

#chart-tooltip {
  z-index: 0;
  pointer-events: none;
}

#chart-tooltip .arrow {
  top: 100%;
  left: 50%;
  transform: translateX(-50%) translateX(-.5rem);
}

.popover-header {
  border-bottom: 0;
}

.popover-body-label, .popover-body-value {
  margin-left: .25rem;
}

.popover-body-indicator {
  display: inline-block;
  width: .5rem;
  height: .5rem;
  border-radius: 50%;
}


.card-chart-stats {
  position: relative; 
  padding-bottom: calc(80px - 1.25rem); 
  overflow: hidden; 
  z-index: 0;
  .chart {
    height: 80px; 
    position: absolute; 
    left: 0; 
    right: 0; 
    bottom: 0;
  }
}


.dashboard-chart {
  width: calc(250px - 1.25rem * 2); 
  height: calc(250px - 1.25rem * 2);
  .d-flex {
    position: absolute; top: 0; left: 0; bottom:0; right:0;
  }
}
